import {PredicateDomain} from "./predicates/predicateDomain";
import {Domain} from "./domain";
import {Hypothesis} from "./hypothesis";
import {Entities} from "./entities/entities";
import {Parser} from "./parser";
import {Factory as PredicateFactory} from "./predicates/factory";
import {Factory as MutationFactory} from "./mutations/factory";
import {Mutation} from "./mutations/mutation";

export interface IActionData {
    decay: number
    name: string
    description: string
    precondition: any
    score: number
    update: {[key: string]: any}
}
export class Action {
    public readonly decay: number
    public readonly description: string
    public readonly name: string
    public readonly score: number

    private precondition: PredicateDomain;
    private update: {[key: string]: Mutation} = {};

    constructor(data: IActionData) {
        this.name = data.name ?? "";
        this.description = data.description ?? ""
        this.precondition = PredicateFactory.Create(data.precondition ?? {type: "true"});
        this.decay = data.decay || 0;
        this.score = data.score || 0;
        Object.keys(data.update || {}).forEach(key => this.update[key] = MutationFactory.Create(data.update[key]));
    }
    
    public Describe(entities: Entities, hypothesis: Hypothesis) {
        return this.parse(this.description, entities, hypothesis);
    }
    public Save(): IActionData {
        return {
            decay: this.decay,
            description: this.description,
            name: this.name,
            precondition: this.precondition.Save(),
            score: this.score,
            update: Object.fromEntries(Object.entries(this.update || {}).map(([key, mutation]) =>
                [key, mutation.Save()]))
        }
    }
    public Unify(domain: Domain, hypotheses: Hypothesis[]) {
        this.precondition.UnifyDomain(domain, hypotheses);
    }
    public Update(domain: Domain, hypothesis: Hypothesis) {
        Object.values(this.update).forEach(mutation => {
            domain.Mutate(mutation, hypothesis);
        })
    }
    
    private parse(text: string, entities: Entities, hypothesis: Hypothesis): string {
        try {
            return Parser.tokenise(text, {
                member: /^\${([^}]+)}/,
                text: /^(.*?)(?=\${|$)/,
            }).map(token => {
                if (token.type === "member") {
                    const [free, format] = token.value.split(":");

                    if (free) {
                        const bound = hypothesis.get(free);
                        const entity = entities.GetEntity(bound as string);

                        if (entity) {
                            return entity.Describe(format);
                        }
                    }
                }
                return token.value;
            }).join("");
        } catch (exception: any) {
            console.error(exception.message);
            return text;
        }
    }
}