import {createDeepSlice} from "./base";

export interface IModeMeta {
    project?: string
    help: {
        subject: string
        open: boolean
    }
}
export interface IModeState {
    name: string,
    meta: IModeMeta
}

const slice = createDeepSlice<IModeState>("mode", {
    meta: {help: {subject: "", open: false}},
    name: "projects",
});

export const {remove, update} = slice.actions;
export default slice.reducer;