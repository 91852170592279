import {Hypothesis} from "../hypothesis";
import {Factory} from "./factory";

export abstract class Parameter {
    public readonly type: string;

    protected constructor(data: any) {
        this.type = data.type;
    }

    public Bind(hypothesis: Hypothesis) : Parameter {
        return Factory.Create(this.Save());
    }
    public abstract Equals(parameter: Parameter, hypothesis: Hypothesis): boolean;
    public abstract Load(data: any): void;
    public abstract Save(): any;
    public abstract Unifies(parameter: Parameter, hypothesis: Hypothesis): boolean;

    public abstract toString(): string

}
