import BorderedBox from "./BorderedBox";
import {Box, IconButton, List, ListItem, TextField} from "@mui/material";
import {ChangeEventHandler, useContext, useState} from "react";
import {Add, Delete} from "@mui/icons-material";
import {FirebaseContext} from "../../firebase/FirebaseProvider";
import {selectProject} from "../../../redux/selectors/firebase";
import {selectMeta} from "../../../redux/selectors/mode";
import {connect} from "react-redux";
import {IModeMeta} from "../../../redux/slices/mode";
import {TFirebaseTable} from "../../../redux/slices/firebase";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

interface ISelectOptions {
    editable: boolean
    label: string
    modeData: IModeMeta
    options: string
    project: TFirebaseTable
    sx?: SxProps<Theme>;
}
function SelectOptions({editable, label, modeData, options, project, sx = {}}: ISelectOptions) {
    const classes = {
        scrolling: {
            height: "calc(100% - 52px)",
            overflowY: "auto",
            '&::-webkit-scrollbar': {
                width: '0.4em'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
                borderRadius: '0.4em'
            }
        }
    };
    const [addOption, setAddOption] = useState("");
    const firebase = useContext(FirebaseContext);
    const selectPath = `projects/${modeData.project}/${options}`;
    const handleAddOptionChange: ChangeEventHandler<HTMLInputElement> = ({target}) =>
        setAddOption(target.value);
    const handleAddOption = () => firebase.set(`${selectPath}/${addOption}`, addOption);
    const handleDeleteOption = (key: string) => () =>
        firebase.remove(`${selectPath}/${key}`);
    const handleOptionChange = (key: string):ChangeEventHandler<HTMLInputElement> => ({target}) =>
        firebase.set(`${selectPath}/${key}`, target.value);

    if (! project) return null;
    return (
        <BorderedBox label={label} sx={{height: "100%", m:1, marginTop: 0, ...sx}}>
            <Box display="flex" m={1} marginLeft={2} marginRight={2}>
                <TextField disabled={!editable} fullWidth label="Key" onChange={handleAddOptionChange} size="small"
                           value={addOption}/>
                <IconButton disabled={!editable || addOption === "" || (project[options] && project[options][addOption] !== undefined)}
                            onClick={handleAddOption} sx={{marginLeft: 1}}>
                    <Add/>
                </IconButton>
            </Box>
            <Box sx={classes.scrolling}>
                <List dense>
                    {Object.entries(project[options] || {}).map(([key, name]) =>
                        <ListItem key={key}>
                            <TextField disabled={!editable} fullWidth label={key} onChange={handleOptionChange(key)}
                                       size="small" value={name}/>
                            <IconButton disabled={!editable} onClick={handleDeleteOption(key)} sx={{marginLeft: 1}}>
                                <Delete/>
                            </IconButton>
                        </ListItem>
                    )}
                </List>
            </Box>
        </BorderedBox>
    );
}
const mapStateToProps = (store: never) => ({
    project: selectProject(store),
    modeData: selectMeta(store)
});

const connected = connect(mapStateToProps, {
})(SelectOptions);

export {connected as SelectOptions};
