import {Factory} from "./factory";
import {Predicate} from "./predicate";

// The unification order is important because attempting to unify an unbound not predicate will usually succeed.
// This ordering attempts to force the hypothesis to unify bound predicates early.
const unifyOrder = ["entity", "relates", "or", "and", "not"];

export abstract class PredicateCompound extends Predicate {
    protected readonly predicates: {[key: string]: Predicate};

    constructor(type: string) {
        super(type);
        this.predicates = {};
    }
    public Load(data: any) {
        Object.keys(data.predicates || {})
            .sort((lhs, rhs) =>
                unifyOrder.indexOf(data.predicates[lhs].type) - unifyOrder.indexOf(data.predicates[rhs].type))
            .forEach(key => {
                this.predicates[key] = Factory.Create(data.predicates[key]);
            });
    }
    public Save(): any {
        return {
            predicates: Object.fromEntries(Object.entries(this.predicates).map(([key, predicate]) => 
                [key, predicate.Save()])),
            type: this.type
        };
    }
}
