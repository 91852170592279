let lastPushTime = 0;
let lastRandChars: number[] = [];
const pushCharacters = '-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz';

/// Credit here: https://gist.github.com/mikelehen/3596a30bd69384624c11
export function PushId() {
    const now = Date.now();
    const timeStampCharacters = [];
    let dividend = now;

    for (let character = 7; character >= 0; --character) {
        timeStampCharacters[character] = pushCharacters.charAt(dividend % 64);
        // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
        dividend = Math.floor(dividend / 64);
    }
    if (dividend !== 0) throw new Error('We should have converted the entire timestamp.');

    if (now !== lastPushTime) {
        for (let character = 0; character < 12; character++) {
            lastRandChars[character] = Math.floor(Math.random() * 64);
        }
    } else {
        // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
        let character = 11;

        for (; character >= 0 && lastRandChars[character] === 63; character--) {
            lastRandChars[character] = 0;
        }
        lastRandChars[character]++;
    }

    let id = timeStampCharacters.join('');

    for (let character = 0; character < 12; ++character) {
        id += pushCharacters.charAt(lastRandChars[character]);
    }
    if (id.length !== 20)
        throw new Error('Length should be 20.');

    lastPushTime = now;
    return id;
}