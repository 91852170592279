import {Domain} from "../domain";
import {Hypothesis} from "../hypothesis";

export abstract class Predicate {
    public readonly type: string;

    protected constructor(data: any) {
        this.type = data.type;
    }

    public abstract describe(aliases?: {[key: string]: string}): string;
    public abstract Load(data: any): void;
    public abstract Save(): any;
    public abstract UnifyDomain(domain: Domain, hypotheses: Hypothesis[]): boolean;
}
