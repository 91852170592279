import {Box} from "@mui/material";
import Parameter from "./Parameter";
import {selectEntities, selectProject} from "../../../../redux/selectors/firebase";
import {connect} from "react-redux";
import {TFirebaseTable} from "../../../../redux/slices/firebase";

function SetPredicateParameters(data: any, project: TFirebaseTable, free?: string[]) {
    if (project.entityTypes) {
        data.entityType.type = "string";
        data.entityType.value = Object.keys(project.entityTypes)[0];
    }
    if (free?.length) {
        data.entityKey.type = "free";
        data.entityKey.value = free[0];
    }
}

interface IPredicateEntityDetails {
    data: any
    editable: boolean
    entities: TFirebaseTable
    free?: string[]
    onChange: (type: any) => void
    project: TFirebaseTable
}
function PredicateEntityDetails({data, editable, entities, free, onChange, project}: IPredicateEntityDetails) {
    const handleMemberChange = (member: string) => (update: any) => {
        if (member === "key" && entities[update.value]) {
            onChange({
                ...data,
                key: update,
                properties: {
                    type: "array",
                    value: entities[update.value].properties
                }
            });
        } else {
            onChange({
                ...data,
                [member]: update
            });
        }
    }

    return (
        <Box m={1}>
            <Parameter data={data.entityKey} editable={editable} free={free} label="Entity Key"
                       onChange={handleMemberChange("entityKey")}
                       types={["free", "string", "select"]} select={
                           Object.fromEntries(Object.entries(entities)
                               .filter(([key, entity]) => entity.type === data.entityType.value)
                               .map(([key, entity]) => [key, entity.name]))}/>
            <Parameter data={data.entityType} editable={editable} free={free} label="Entity Type"
                       onChange={handleMemberChange("entityType")}
                       types={["free", "string", "select"]} select={project.entityTypes}/>
            <Parameter data={data.properties} editable={editable} free={free} label="Properties"
                       onChange={handleMemberChange("properties")} types={["free", "array"]}/>
        </Box>
    );
}
const mapStateToProps = (store: never) => ({
    entities: selectEntities(store),
    project: selectProject(store)
});

const connected = connect(mapStateToProps, {
})(PredicateEntityDetails);

export {connected as PredicateEntityDetails, SetPredicateParameters};