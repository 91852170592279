import {Domain} from "./domain";
import {Action} from "./action";
import {Hypothesis} from "./hypothesis";
import {Plan} from "./plan";
import {Entities, TAliases} from "./entities/entities";
import {Logger} from "./logger";
import {Critic} from "./critic";

export interface ITestResults {
    score: number,
    plans: string[]
}

type TRawData = {[key: string]: any};
export class Narrator {
    private actions: {[key: string]: Action};
    private aliases: TAliases
    private critic: Critic;
    private readonly domain: Domain;
    private readonly entities: Entities;

    constructor(private logger?: Logger) {
        this.actions = {};
        this.aliases = {};
        this.critic = new Critic();
        this.domain = new Domain(logger);
        this.entities = new Entities();
    }

    public ClearLog() {
        this.logger?.Clear();
    }
    public Describe(plan: Plan) {
        return plan.Describe(this.entities)
    }
    public ExecutePlan(plan: Plan) {
        this.logger?.LogInfo(`Executed: ${this.Describe(plan)}`);
        plan.Execute(this.domain);
        this.critic.ExecutedPlan(plan)
    }
    public FindAllPlans(): Plan [] {
        return Object.values(this.actions).map(action => {
            const hypotheses: Hypothesis[] = [new Hypothesis()];

            action.Unify(this.domain, hypotheses);
            return hypotheses.map(hypothesis => new Plan(action, hypothesis));
        }).flat();
    }
    public FindPlan(params: {mode: "random" | "first"}): Plan | undefined {
        const start = Date.now();

        for (let action of this.critic.Sort(Object.values(this.actions))) {
            const hypotheses: Hypothesis[] = [new Hypothesis()];

            this.logger?.LogInfo(`Testing action: ${action.name}`);
            action.Unify(this.domain, hypotheses);
            hypotheses.forEach(hypothesis => {
                this.logger?.LogInfo(`Action ${action.name} unifies with ${hypothesis}`);
            });
            if (hypotheses.length > 0) {
                const hypothesis = params.mode === "random" ?
                    hypotheses[Math.floor(Math.random() * hypotheses.length)] : hypotheses[0];

                this.logger?.LogInfo(`Found plan in ${((Date.now() - start) / 1000).toFixed(2)} seconds`);
                return new Plan(action, hypothesis);
            }
        }
    }
    public GetAliases() {
        return this.aliases;
    }
    public Load(actions: TRawData, entities: TRawData, predicates: TRawData) {
        const loadIfData = (name: string, data: TRawData, callback: () => void) => {
            const length = Object.keys(data || {}).length;

            if (length) {
                callback();
                this.logger?.LogInfo(`Loaded ${Object.keys(this.actions).length} ${name}`);
            }            
        }

        loadIfData("actions", actions, () => {
            this.actions = Object.fromEntries(Object.entries(actions).map(([key, actionData]) =>
                [key, new Action(actionData)]));
        });
        loadIfData("predicates", predicates, () => this.domain.Load(predicates));
        loadIfData("entities", entities, () => this.entities.Load(entities));
        this.aliases = this.entities.aliases();
        this.logger && (this.logger.aliases = this.aliases);
    }
    public ResetActions(actions: TRawData) {
        this.actions = Object.fromEntries(Object.entries(actions).map(([key, actionData]) =>
            [key, new Action(actionData)]));
    }
    public ResetEntities(entities: TRawData) {
        this.entities.Clear();
        this.entities.Load(entities);
        this.aliases = this.entities.aliases();
        this.logger && (this.logger.aliases = this.aliases);
    }
    public ResetDomain(predicates: TRawData) {
        this.domain.Clear();
        this.domain.Load(predicates);
    }
    public TestAction(actionKey: string): ITestResults {
        const action = this.actions[actionKey];
        const start = Date.now();

        if (action) {
            const hypotheses: Hypothesis[] = [new Hypothesis()];

            action.Unify(this.domain, hypotheses);
            hypotheses.forEach(hypothesis => {
                this.logger?.LogInfo(`Action ${action.name} unifies with ${hypothesis}`);
            });
            if (hypotheses.length > 0) {
                const score = this.critic.GetScore(action);

                this.logger?.LogInfo(`Found hypothesis for ${action.name} ${((Date.now() - start) / 1000).toFixed(2)} seconds`);
                this.logger?.LogInfo(`Critic scores plan ${score}`);
                return {score, plans: hypotheses.map(hypothesis => new Plan(action, hypothesis).Describe(this.entities))};
            }
        }
        return {score: 0, plans: []};
    }
    public describe = () => this.domain.describe(this.aliases);
}
