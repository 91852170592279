import {Hypothesis} from "./hypothesis";
import {Action} from "./action";
import {Domain} from "./domain";
import {Entities} from "./entities/entities";

export class Plan {
    constructor(private action: Action, private hypothesis: Hypothesis) {}

    public Describe(world: Entities) {
        return this.action.Describe(world, this.hypothesis);
    }
    public Execute(domain: Domain) {
        this.action.Update(domain, this.hypothesis);
    }
    public GetAction() {
        return this.action;
    }
    public GetHypothesis() {
        return this.hypothesis;
    }
    public toString(aliases: Record<string, string> = {}): string {
        const hypothesis = this.hypothesis.toString(aliases);

        return hypothesis.length === 0 ? this.action.name :
            `${this.action.name} using ${this.hypothesis.toString(aliases)}`;
    }
}