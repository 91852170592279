import {ReactElement} from "react";
import {Box, useTheme} from "@mui/material";

interface IModePortal {
    children: ReactElement | ReactElement[]
}
export default function ModePortal({children}: IModePortal) {
    const theme = useTheme();
    const styles = {
        content: {
            height: "100%"
        },
        inner: {
            backgroundColor: theme.palette.background.paper,
            height: "100%",
            padding: 1
        }
    };

    return (
        <Box sx={styles.content}>
            <Box sx={styles.inner}>
                {children}
            </Box>
        </Box>
    );
}