import {Box, IconButton, Tab, useTheme} from "@mui/material";
import {SyntheticEvent, useEffect, useState} from "react";
import Predicate from "../predicates/Predicate";
import BorderedBox from "../BorderedBox";
import {IActionData} from "../../../../aristotle/action";
import {Factory} from "../../../../aristotle/predicates/factory";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {ActionDetails} from "./ActionDetails";
import {Help} from "@mui/icons-material";
import {connect} from "react-redux";
import {update as updateMode} from "../../../../redux/slices/mode";
import { Update } from "../../../../redux/slices/base";
import {UpdatePredicates} from "./UpdatePredicates";

const freeParametersPattern = /{([^:}]*):?[^}]*}/g;

interface IActionDetails {
    action: IActionData
    editable: boolean
    onChange: (member: string, value: any) => void
    updateMode: Update
}
function EditAction({action, editable, onChange, updateMode}: IActionDetails) {
    const theme = useTheme();
    const styles = {
        content: {
            display: "flex",
            flexDirection: "column",
            margin: 1,
            height: "100%",
            overflowY: "auto",
            '&::-webkit-scrollbar': {
                width: '0.4em'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
                borderRadius: '0.4em'
            }
        },
        tabPanel: {
            height: "calc(100% - 96px)"
        },
        tabList: {
            display: "flex",
            borderBottom: 1,
            borderColor: 'divider',
            margin: theme.spacing(1)
        }
    };
    const getFree = (source: string) => Array.from(source.matchAll(freeParametersPattern))
        .map(match => match[1]).filter((value, index, self) => self.indexOf(value) === index);
    const [free, setFree] = useState<string[]>([]);
    const [tab, setTab] = useState("details");
    const handleHelpClick = () => {
        updateMode({store: ["meta", "help"], update: {subject: `edit.actions.${tab}`, open: true}})
    }
    const handlePredicateChange = (predicate: any) => {
        onChange("precondition", predicate);
    }
    const handleUpdateChange = (update: any) => 
        onChange("update", update);
    const handleTabChange = (event: SyntheticEvent, newValue: string) => {
        setTab(newValue);
    };
    const handleTextChange = (member: string, value: any) => {
        if (member === "description") {
            setFree(getFree(value + Factory.Create(action.precondition).describe()));
        }
        onChange(member, value);
    }

    useEffect(() => {
        const precondition = Factory.Create(action.precondition);

        setFree(getFree(action.description + precondition.describe()));
    }, [action]);
    return (
        <BorderedBox label={action.name}>
            <TabContext value={tab}>
                <Box sx={styles.tabList}>
                    <TabList onChange={handleTabChange} aria-label="change action details" sx={{flexGrow: 1}}>
                        <Tab label="Details" value="details" />
                        <Tab label="Precondition" value="precondition" />
                        <Tab label="Update" value="update" />
                    </TabList>
                    <IconButton onClick={handleHelpClick} sx={{marginRight:1}}>
                        <Help/>
                    </IconButton>
                </Box>
                <TabPanel sx={styles.tabPanel} value="details">
                    <ActionDetails action={action} editable={editable} onChange={handleTextChange}/>
                </TabPanel>
                <TabPanel sx={styles.tabPanel} value="precondition">
                    <Predicate data={action.precondition} editable={editable} free={free} label={"none"}
                               onChange={handlePredicateChange}/>
                </TabPanel>
                <TabPanel sx={styles.tabPanel} value="update">
                    <UpdatePredicates data={action.update} editable={editable} free={free}
                                      onChange={handleUpdateChange}/>
                </TabPanel>
            </TabContext>
        </BorderedBox>
    );
}
const mapStateToProps = (store: never) => ({
});

const connected = connect(mapStateToProps, {
    updateMode
})(EditAction);

export {connected as EditAction};