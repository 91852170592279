import {ActionCreatorWithPayload, createSlice, PayloadAction} from "@reduxjs/toolkit";

export type TUpdate = (update: IUpdate) => void
interface IUpdate {
    store?: string[]
    update: unknown
}
export type TRemove = (remove: IRemove) => void 
interface IRemove {
    remove: string[]
}

export type Update = ActionCreatorWithPayload<IUpdate,  string>;
export const createDeepSlice = function<InitialState>(name: string, initialState: InitialState) {
    return createSlice({
        name,
        initialState,
        reducers: {
            remove(state, action: PayloadAction<IRemove>) {
                const path = [...action.payload.remove];
                let root: any = state;
                let member = path.shift() as string;

                while (path.length) {
                    if (root[member] !== undefined) {
                        root = root[member];
                        member = path.shift() as string;
                    } else {
                        break;
                    }
                }
                if (path.length === 0) {
                    delete root[member];
                }
            },
            update(state, action: PayloadAction<IUpdate>) {
                if (action.payload.store) {
                    const path = [...action.payload.store];
                    let root: any = state;
                    let branch: unknown = action.payload.update;
                    let member = path.shift() as string;

                    while (path.length) {
                        if (root[member] !== undefined) {
                            root = root[member];
                            member = path.shift() as string;
                        } else {
                            break;
                        }
                    }
                    while (path.length) {
                        branch = {[path.shift() as string]: branch};
                    }
                    root[member] = branch;
                } else {
                    return action.payload.update as InitialState;
                }
            },
        }
    });
}
