import {Box, Button, IconButton, Link, MenuItem, TextField, useTheme} from "@mui/material";
import {IModeMeta} from "../../../redux/slices/mode";
import {ChangeEventHandler, useContext, useEffect, useState} from "react";
import {TFirebaseTable} from "../../../redux/slices/firebase";
import {FirebaseContext} from "../../firebase/FirebaseProvider";
import {selectMeta} from "../../../redux/selectors/mode";
import {connect} from "react-redux";
import {selectActions, selectEntities, selectPredicates, selectProject} from "../../../redux/selectors/firebase";
import {SelectOptions} from "./SelectOptions";
import BorderedBox from "./BorderedBox";
import { getStorage, ref, uploadString } from "firebase/storage";
import {Help, Link as LinkIcon} from "@mui/icons-material";
import {Update} from "../../../redux/slices/base";
import {update as updateMode} from "../../../redux/slices/mode";

interface IProject {
    actions: TFirebaseTable
    editable: boolean
    entities: TFirebaseTable
    predicates: TFirebaseTable
    modeData: IModeMeta
    project: TFirebaseTable
    updateMode: Update
}
function Project({actions, editable, entities, predicates, modeData, project, updateMode}: IProject) {
    const theme = useTheme();
    const styles = {
        content: {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            margin: theme.spacing(1),
            height: "calc(100vh - 216px)"
        },
        flexRowItem: {
            marginLeft: theme.spacing(1)
        }
    };
    const firebase = useContext(FirebaseContext);
    const [updateDisabled, setUpdateDisabled] = useState(false);
    const [saving, setSaving] = useState(false);
    const [working, setWorking] = useState<TFirebaseTable>({});
    const handleExportClick = () => {
        const storage = getStorage();
        const path = project.path || project.name;

        setUpdateDisabled(true);
        uploadString(ref(storage, `${path}/actions.json`), JSON.stringify(actions)).then(() =>
            uploadString(ref(storage, `${path}/entities.json`), JSON.stringify(entities)).then(() =>
                uploadString(ref(storage, `${path}/predicates.json`), JSON.stringify(predicates)).then(() =>
                    setUpdateDisabled(false)
            )));
    };
    const handleHelpClick = (subject: string) => () => {
        updateMode({store: ["meta", "help"], update: {subject, open: true}})
    }
    const handleMemberChange = (member: string):ChangeEventHandler<HTMLInputElement> =>
        ({target}) => setWorking(working => ({
            ...working,
            [member]: target.value
        }));
    const handleSaveClick = () => {
        setSaving(true);
        firebase.set(`projects/${modeData.project}`, working).then(() =>
            setSaving(false));
    }
    const saveDisabled = !editable || JSON.stringify(project) === JSON.stringify(working) || saving;
    const demoPath = `https://aristotle.logjam.co.uk/demo?project=${project?.path || project?.name}`;

    useEffect(() => {
        setWorking(project);
    }, [project]);
    if (! working) return null;
    return (
        <Box display="flex" flexDirection="column" height="100%" m={1}>
            <Box sx={styles.content}>
                <Box display="flex" flexDirection="column" flexGrow={1} height="80%" m={1}>
                    <Box display="flex" m={1}>
                        <TextField disabled={!editable} fullWidth label="Name"
                                   onChange={handleMemberChange("name")}
                                   size="small" value={working.name || ""}/>
                        <TextField disabled={!editable} fullWidth label="Path"
                                   onChange={handleMemberChange("path")} size="small"
                                   sx={styles.flexRowItem} value={working.path || ""}/>
                        <TextField disabled={!editable} fullWidth select label="Visibility"
                                   onChange={handleMemberChange("visibility")} size="small"
                                   sx={styles.flexRowItem} value={working.visibility || ""}>
                            <MenuItem disabled value={""}>Set Visibility</MenuItem>
                            <MenuItem value="public">Public</MenuItem>
                            <MenuItem value="private">Private</MenuItem>
                        </TextField>
                    </Box>
                    <Box m={1}>
                        <TextField disabled={!editable} fullWidth label="Description" multiline
                                   onChange={handleMemberChange("description")} rows={4}
                                   value={working.description || ""}/>
                    </Box>
                    {modeData.project &&
                    <Box display="flex" height="60%">
                        <BorderedBox label="Select Options" sx={{flexGrow: 1, marginLeft: 1, marginRight: 1}}>
                            <Box alignItems="flex-start" display="flex" height="100%">
                                <SelectOptions editable={editable} label="Entity Types"
                                               options="entityTypes" sx={{flexGrow: 1}}/>
                                <IconButton onClick={handleHelpClick("edit.project.entityTypes")}>
                                    <Help/>
                                </IconButton>
                                <SelectOptions editable={editable} label="Relationship Types"
                                               options="relationshipTypes" sx={{flexGrow: 1}}/>
                                <IconButton onClick={handleHelpClick("edit.project.relationshipTypes")}>
                                    <Help/>
                                </IconButton>
                            </Box>
                        </BorderedBox>
                    </Box>
                    }
                </Box>
                <BorderedBox label="Demo" sx={{height: "20%", m:2, marginTop: 0}}>
                    <Box display="flex" margin={1}>
                        <Box flexGrow={1}>
                            <Link href={demoPath} target="_blank" variant="body2">
                                {demoPath}
                            </Link>
                            <IconButton onClick={() => navigator.clipboard.writeText(demoPath)}>
                                <LinkIcon/>
                            </IconButton>
                        </Box>
                        <Button disabled={! editable || updateDisabled} onClick={handleExportClick}
                                variant="contained">
                            Export
                        </Button>
                        <IconButton onClick={handleHelpClick("edit.project.export")} sx={{marginRight:4}}>
                            <Help/>
                        </IconButton>
                    </Box>
                </BorderedBox>
            </Box>
            <Box display="flex" justifyContent="right" marginRight={3}>
                <Button sx={styles.flexRowItem} disabled={saveDisabled} onClick={handleSaveClick}
                        variant="contained">
                    Save
                </Button>
            </Box>
        </Box>
    );
}
const mapStateToProps = (store: never) => ({
    actions: selectActions(store),
    entities: selectEntities(store),
    predicates: selectPredicates(store),
    project: selectProject(store),
    modeData: selectMeta(store)
});

export default connect(mapStateToProps, {
    updateMode
})(Project);
