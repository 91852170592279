import {Factory} from "./factory";
import {Entity} from "./entity";

export type TAliases = {[key: string]: string};
export class Entities {
    private entities: {[key: string]: Entity} = {};

    public Clear() {
        this.entities = {};
    }
    public Load(entities: {[key: string]: any}) {
        this.entities = Object.fromEntries(Object.entries(entities).map(([key, entityData]) =>
            [key, Factory.Create(entityData)]));
    }
    public GetEntity(key: string) {
        return this.entities[key];
    }
    public aliases = () => Object.fromEntries(Object.entries(this.entities)
        .map(([key, entity]) => [key, entity.Describe()]));
}