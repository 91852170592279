import BorderedBox from "../BorderedBox";
import {Autocomplete, Box, Divider, FormControlLabel, MenuItem, Switch, TextField, useTheme} from "@mui/material";
import {ChangeEventHandler, useEffect, useState} from "react";
import {Factory} from "../../../../aristotle/parameters/factory";

const defaultTypes = ["free", "boolean", "number", "string", "array"];

interface IParameter {
    data: any
    editable: boolean
    free?: string[]
    label: string
    onChange: (data: any) => void
    select?: {[key: string]: string}
    types?: string[]
}
export default function Parameter({data, editable, free = [], label, onChange, select = {}, types = defaultTypes}: IParameter) {
    const theme = useTheme();
    const styles = {
        type: {
            margin: theme.spacing(1),
            width: 120
        },
        value: {
            flexGrow: 1,
            margin: theme.spacing(1)
        }
    };
    const [type, setType] = useState(data.type);
    const handleTypeChange:ChangeEventHandler<HTMLInputElement> = ({target}) => {
        const type = target.value === "select" ? "string" : target.value;
        const value = target.value === "select" ? Object.keys(select)[0] : undefined;

        setType(target.value);
        onChange(Factory.Create({type, value}).Save());
    }
    const handleArrayChange:ChangeEventHandler<HTMLInputElement> = ({target}) => {
        onChange({
            type: "array",
            value: target.value.split(",").map(entry => entry.trim())
        });
    }
    const handleFreeValueChange = (event: any, newValue: string) => {
        onChange(Factory.Create({type: data.type, value: newValue}).Save());
    }
    const handleValueChange: ChangeEventHandler<HTMLInputElement> = ({target}) => {
        onChange(Factory.Create({type: data.type, value: target.value}).Save());
    }

    useEffect(() => {
        if (types.includes("select") && select[data.value]) {
            setType("select");
        } else {
            setType(data.type);
        }
    }, [data, select, types]);
    return (
        <BorderedBox label={label}>
            <Box display="flex">
                <TextField disabled={!editable} label="Type" onChange={handleTypeChange} select size="small"
                           sx={styles.type} value={type || "none"}>
                    <MenuItem value="none" disabled><em>None</em></MenuItem>
                    {types.includes("free") && <MenuItem value="free">Free</MenuItem>}
                    <Divider/>
                    {types.includes("select") && <MenuItem value="select">Select</MenuItem>}
                    {types.includes("boolean") && <MenuItem value="boolean">Boolean</MenuItem>}
                    {types.includes("number") && <MenuItem value="number">Number</MenuItem>}
                    {types.includes("string") && <MenuItem value="string">String</MenuItem>}
                    {types.includes("array") && <MenuItem value="array">Array</MenuItem>}
                </TextField>
                {type === "array" &&
                    <TextField disabled={!editable} label="Value" onChange={handleArrayChange} size="small"
                               sx={styles.value} value={(data.value || []).join(", ")}/>
                }
                {type === "string" &&
                    <TextField disabled={!editable} label="Value" onChange={handleValueChange} size="small"
                               sx={styles.value} value={data.value || ""}/>
                }
                {type === "number" &&
                    <TextField disabled={!editable} label="Value" onChange={handleValueChange} size="small"
                               sx={styles.value} value={data.value || 0} type="number"/>
                }
                {type === "boolean" &&
                    <FormControlLabel control={
                        <Switch onChange={handleValueChange} size="small" value={data.value}/>
                    } disabled={!editable} label="Value" sx={styles.value}/>
                }
                {type === "select" &&
                    <TextField disabled={!editable} label="Select" onChange={handleValueChange} size="small"
                               sx={styles.value || ""} value={data.value} select>
                        <MenuItem value="" disabled><em>None</em></MenuItem>
                        {Object.keys(select)
                            .sort((lhs, rhs) => select[lhs].localeCompare(select[rhs]))
                            .map(key => (
                                <MenuItem key={key} value={key}>{select[key]}</MenuItem>
                            ))
                        }
                    </TextField>
                }
                {type === "free" &&
                    <Autocomplete disabled={!editable} clearOnBlur freeSolo handleHomeEndKeys
                                  onChange={handleFreeValueChange} options={free} renderInput={
                                      (params) =>
                                          <TextField {...params} label="Value" size="small"/>
                                  } selectOnFocus sx={styles.value} value={data.value}/>
                }
            </Box>
        </BorderedBox>
    );
}