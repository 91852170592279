import Welcome from "../modes/Welcome";
import {createElement, FunctionComponent, useContext, useEffect, useState} from "react";
import {connect} from "react-redux";
import {selectMeta, selectMode} from "../../redux/selectors/mode";
import {Box} from "@mui/material";
import Projects from "../modes/Projects";
import {IModeMeta, IModeState} from "../../redux/slices/mode";
import LocationState from "./LocationState";
import {Play} from "../modes/play/Play";
import Navigation from "./Navigation";
import {Edit} from "../modes/edit/Edit";
import {Factory as EntityFactory} from "../../aristotle/entities/factory";
import {Factory as PredicateFactory} from "../../aristotle/predicates/factory";
import {FirebaseContext} from "../firebase/FirebaseProvider";
import {TRemove, TUpdate} from "../../redux/slices/base";
import {remove, TFirebaseTable, update} from "../../redux/slices/firebase";
import {Action, IActionData} from "../../aristotle/action";
import {Narrator} from "../../aristotle/narrator";
import {Logger} from "../../aristotle/logger";
import {selectActions, selectEntities, selectPredicates} from "../../redux/selectors/firebase";
import {Help} from "./Help";

interface IProps {
    actions: TFirebaseTable
    entities: TFirebaseTable
    mode: IModeState
    modeData: IModeMeta
    predicates: TFirebaseTable
    remove: TRemove
    update: TUpdate
}
function Portal({actions, entities, mode, modeData, predicates, remove, update}: IProps) {
    const styles = {
        portal: {
            overflow: "hidden",
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
        content: {
            height: "calc(100% - 48px)"
        }
    };
    const firebase = useContext(FirebaseContext);
    const [logger, setLogger] = useState<Logger>();
    const [narrator, setNarrator] = useState<Narrator>();
    const modes: {[mode: string]: FunctionComponent<any>} = {
        edit: Edit as FunctionComponent<any>,
        play: Play as FunctionComponent<any>,
        projects: Projects as FunctionComponent<any>,
        welcome: Welcome as FunctionComponent<any>
    };

    useEffect(() => {
        if (narrator === undefined) {
            const logger = new Logger();
            const narrator = new Narrator(logger);

            setLogger(logger)
            setNarrator(narrator);
        }
    }, [narrator]);
    useEffect(() => {
        if (actions && narrator) {
            narrator.ResetActions(actions);
            logger?.LogInfo("Loaded actions");
        }
    }, [actions, logger, narrator]);
    useEffect(() => {
        if (entities && narrator) {
            narrator.ResetEntities(entities);
            logger?.LogInfo("Loaded entities");
        }
    }, [entities, logger, narrator]);
    useEffect(() => {
        if (predicates && narrator) {
            narrator.ResetDomain(predicates);
            logger?.LogInfo("Loaded predicates");
        }
    }, [predicates, logger, narrator]);
    useEffect(() => {
        if (modeData.project) {
            firebase.track(`actions/${modeData.project}`, (actions) => {
                update({
                    store: ["actions"],
                    update: Object.fromEntries(Object.entries(actions || {}).map(([key, data]) =>
                        [key, new Action(data as IActionData).Save()]))
                });
            });
            firebase.track(`entities/${modeData.project}`, (entities) => {
                update({
                    store: ["entities"],
                    update: Object.fromEntries(Object.entries(entities || {}).map(([key, data]) =>
                        [key, EntityFactory.Create(data).Save()]))
                });
            });
            firebase.track(`predicates/${modeData.project}`, (predicates) => {
                update({
                    store: ["predicates"],
                    update: Object.fromEntries(Object.entries(predicates || {}).map(([key, data]) =>
                        [key, PredicateFactory.Create(data).Save()]))
                });
            });
            firebase.track(`projects/${modeData.project}`, (project) => {
                update({
                    store: ["project"],
                    update: project || {}
                });
            });
            return () => {
                remove({ remove: ["actions"] });
                firebase.untrack(`actions/${modeData.project}`);
                remove({ remove: ["entities"] });
                firebase.untrack(`entities/${modeData.project}`);
                remove({ remove: ["predicates"] });
                firebase.untrack(`predicates/${modeData.project}`);
                remove({ remove: ["project"] });
                firebase.untrack(`projects/${modeData.project}`);
            };
        }
    }, [modeData.project, remove, firebase, update]);
    return (
        <Box sx={styles.portal}>
            <Navigation/>
            <LocationState/>
            <Help/>
            <Box sx={styles.content}>
                {createElement(modes[mode.name] || modes.welcome, {logger, narrator})}
            </Box>
        </Box>
    );
}
const mapStateToProps = (store: never) => ({
    actions: selectActions(store),
    entities: selectEntities(store),
    mode: selectMode(store),
    modeData: selectMeta(store),
    predicates: selectPredicates(store)
});

export default connect(mapStateToProps, {
    remove,
    update
})(Portal);
