import BorderedBox from "../BorderedBox";
import {Box, MenuItem, TextField, useTheme} from "@mui/material";
import {ChangeEventHandler} from "react";
import Predicate from "../predicates/Predicate";

interface IMutation {
    data: any
    editable: boolean
    free?: string []
    onChange: (update: any) => void
}
export default function Mutation({data, editable, free, onChange}: IMutation) {
    const theme = useTheme();
    const styles = {
        content: {
            display: "flex",
            flexDirection: "column",
        },
        type: {
            flexGrow: 1,
            margin: theme.spacing(1)
        }
    };
    const handlePredicateChange = (predicate: any) => {
        onChange({
            ...data,
            predicate
        })
    }
    const handleTypeChange:ChangeEventHandler<HTMLInputElement> = ({target}) => {
        onChange({
            ...data,
            type: target.value
        });
    };

    return (
        <BorderedBox>
            <Box sx={styles.content}>
                <Box display="flex" flexDirection="column">
                    <Box display="flex">
                        <TextField disabled={!editable} label="Type" onChange={handleTypeChange} select size="small"
                                   sx={styles.type} value={data.type || ""}>
                            <MenuItem value="" disabled><em>None</em></MenuItem>
                            <MenuItem value="add">Add</MenuItem>
                            <MenuItem value="delete">Delete</MenuItem>
                        </TextField>
                    </Box>
                    <Box m={1}>
                        <Predicate data={data.predicate} editable={editable} free={free} label="Predicate"
                                   onChange={handlePredicateChange}/>
                    </Box>
                </Box>
            </Box>
        </BorderedBox>
    );
}