import {TFirebaseTable} from "../../redux/slices/firebase";
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, FormControlLabel, FormGroup, Switch, TextField,
    Typography} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import {ChangeEvent, useContext, useState} from "react";
import {RoleDetails} from "./RoleDetails";
import {IProjectRoles} from "../../redux/slices/user";
import {FirebaseContext} from "../firebase/FirebaseProvider";

const defaultAdd = {
    email: "",
    roles: {
        admin: false,
        editor: true,
        viewer: true,
        projects: {}
    }
};
interface IInvite {
    email: string
    roles: IProjectRoles
}
interface IUserDetails {
    invites: TFirebaseTable
}
export function InviteDetails({invites}: IUserDetails) {
    const firebase = useContext(FirebaseContext);
    const [addInvite, setAddInvite] = useState<IInvite>(defaultAdd);
    const [expandedInvite, setExpandedInvite] = useState("");
    const handleInviteChange = (invite: string) => () => {
        if (invite === expandedInvite) {
            setExpandedInvite("");
        } else {
            setExpandedInvite(invite)
        }
    };
    const handleAddClick = () => {
        firebase.push("invites", {...addInvite, invited: Date.now()}).catch(console.error);
        setAddInvite(defaultAdd);
    };
    const handleDeleteClick = (inviteId: string) => () => {
        firebase.remove(`invites/${inviteId}`).catch(console.error);
        setAddInvite(defaultAdd);
    };
    const handleEmailUpdate = ({target}: ChangeEvent<HTMLInputElement>) => {
        setAddInvite(addInvite => ({
            ...addInvite,
            email: target.value
        }));
    }
    const handleRoleToggle = (role: "admin" | "editor" | "viewer") => () => {
        setAddInvite(addInvite => ({
            ...addInvite,
            roles: {
                ...addInvite.roles,
                [role]: !addInvite.roles[role]
            }
        }));
    };
    const styles = {
        contents: {
            height: "60vh",
            width: 400
        },
        email: {
            color: "text.secondary",
        },
        name: {
        },
        summary: {
            display: "flex",
            justifyContent: "space-between",
            width: "100%"
        }
    };

    return (
        <Box sx={styles.contents}>
            <Accordion expanded={expandedInvite === "add"} onChange={handleInviteChange("add")}>
                <AccordionSummary expandIcon={<ExpandMore/>}>
                    <Box sx={styles.summary}>
                        <Typography sx={styles.name}>Add New Invite</Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <TextField fullWidth label="email" onChange={handleEmailUpdate} size="small"
                               value={addInvite.email}/>
                    <FormGroup>
                        <FormControlLabel control={
                            <Switch checked={addInvite.roles.admin} onChange={handleRoleToggle("admin")}/>
                        } label="Admin"/>
                        <FormControlLabel control={
                            <Switch checked={addInvite.roles.editor} onChange={handleRoleToggle("editor")}/>
                        } label="Editor"/>
                        <FormControlLabel control={
                            <Switch checked={addInvite.roles.viewer} onChange={handleRoleToggle("viewer")}/>
                        } label="Viewer"/>
                    </FormGroup>
                    <Box display="flex" justifyContent="flex-end">
                        <Button disabled={addInvite.email === ""} onClick={handleAddClick} variant="contained">
                            Add
                        </Button>
                    </Box>
                </AccordionDetails>
            </Accordion>
            {Object.entries(invites || {}).map(([key, invite]) =>
                <Accordion expanded={expandedInvite === key} onChange={handleInviteChange(key)} key={key}>
                    <AccordionSummary expandIcon={<ExpandMore/>}>
                        <Box sx={styles.summary}>
                            <Typography sx={styles.name}>{invite.email}</Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        {invite.accepted &&
                            <Box sx={styles.summary}>
                                <Typography sx={styles.name}>Accepted</Typography>
                                <Typography sx={styles.email}>{new Date(invite.accepted).toLocaleString()}</Typography>
                            </Box>
                        }
                        <RoleDetails roles={invite.roles} inviteId={key}/>
                        <Box display="flex" justifyContent="flex-end">
                            <Button onClick={handleDeleteClick(key)} variant="contained">
                                Delete
                            </Button>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            )}
        </Box>
    );
}