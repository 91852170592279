import {Box, IconButton, List, ListItemButton, ListItemText, MenuItem, TextField, useTheme} from "@mui/material";
import {Factory} from "../../../../aristotle/mutations/factory";
import {Add, Delete} from "@mui/icons-material";
import {ChangeEventHandler, useEffect, useState} from "react";
import {PushId} from "../../../../utility/pushId";
import Mutation from "./Mutation";
import {TFirebaseTable} from "../../../../redux/slices/firebase";
import {selectEntities} from "../../../../redux/selectors/firebase";
import {connect} from "react-redux";

interface IUpdate {
    data: any
    editable: boolean
    entities: TFirebaseTable
    free?: string []
    onChange: (update: any) => void
}
function UpdatePredicates({data, editable, entities, free, onChange}: IUpdate) {
    const theme = useTheme();
    const styles = {
        content: {
            display: "flex",
            flexDirection: "column",
        },
        type: {
            flexGrow: 1,
            margin: theme.spacing(1)
        },
        updates: {
            flexGrow: 1,
            overflowY: "auto",
            '&::-webkit-scrollbar': {
                width: '0.4em'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
                borderRadius: '0.4em'
            }
        }
    };
    const [addType, setAddType] = useState("add");
    const [selected, setSelected] = useState<string>();
    const aliases = Object.fromEntries(Object.entries(entities).map(([key, entity]) => [key, entity.name]));
    const handleAddMutation = () => {
        const mutation = Factory.Create({type: addType});
        const key = PushId();

        onChange({
            ...data,
            [key]: mutation.Save()
        });
        setSelected(key);
    };
    const handleMutationChange = (key: string) => (mutation: any) => {
        onChange({
            ...data,
            [key]: mutation
        });
    }
    const handleMutationClick = (key: string) => () => setSelected(key);
    const handleMutationDeleteClick = () => {
        if (selected) {
            delete data[selected];
            setSelected(undefined);
            onChange(data);
        }
    };
    const handleTypeChange:ChangeEventHandler<HTMLInputElement> = ({target}) => {
        setAddType(target.value);
    };

    useEffect(() => {
        const mutations = Object.keys(data);

        if (selected === undefined && mutations.length > 0) {
            setSelected(mutations[0]);
        } else if (selected !== undefined && ! mutations.includes(selected)) {
            setSelected(undefined);
        }
    }, [data, selected])
    return (
        <Box display="flex">
            <Box display="flex" flexDirection="column" height="100%" m={1} width={200}>
                <Box display="flex" m={1} width={200}>
                    <TextField disabled={!editable} label="Type" onChange={handleTypeChange} select size="small"
                               sx={styles.type} value={addType}>
                        <MenuItem value="" disabled><em>None</em></MenuItem>
                        <MenuItem value="add">Add</MenuItem>
                        <MenuItem value="delete">Delete</MenuItem>
                    </TextField>
                    <IconButton disabled={!editable} onClick={handleAddMutation}>
                        <Add/>
                    </IconButton>
                </Box>
                <Box sx={styles.updates}>
                    <List dense>
                        {Object.keys(data).map((key) =>
                            <ListItemButton key={key} onClick={handleMutationClick(key)} selected={selected === key}>
                                <ListItemText primary={Factory.Create(data[key]).describe(aliases)}/>
                            </ListItemButton>
                        )}
                    </List>
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" flexGrow={1} height="100%" m={1}>
                {selected && data[selected] &&
                    <Mutation data={data[selected]} editable={editable} free={free}
                              onChange={handleMutationChange(selected)}/>
                }
            </Box>
            <Box>
                {selected &&
                <IconButton disabled={!editable} onClick={handleMutationDeleteClick}>
                    <Delete/>
                </IconButton>}
            </Box>
        </Box>
    );
}
const mapStateToProps = (store: never) => ({
    entities: selectEntities(store)
});

const connected = connect(mapStateToProps, {
})(UpdatePredicates);

export {connected as UpdatePredicates};