import {PredicateNot} from "./predicateNot";
import {PredicateOr} from "./predicateOr";
import {PredicateAnd} from "./predicateAnd";
import {PredicateEntity} from "./predicateEntity";
import {PredicateRelates} from "./predicateRelates";
import {PredicateDomain} from "./predicateDomain";
import {PredicateTrue} from "./predicateTrue";

export class Factory {
    private static predicateTypes: {[key: string]: any} = {
        and: PredicateAnd,
        or: PredicateOr,
        not: PredicateNot,
        entity: PredicateEntity,
        relates: PredicateRelates,
        true: PredicateTrue
    }
    public static Create(data: any): PredicateDomain {
        try {
            const predicate = new Factory.predicateTypes[data.type](data);

            predicate.Load(data);
            return predicate;
        } catch (exception) {
            console.error(`Failed to create predicate with this data: ${JSON.stringify(data)}`);
            throw exception;
        }
    }
}