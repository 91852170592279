import {Archive, Favorite, FavoriteBorder, Luggage, PanTool, People, Person, Place, PlaylistAddCheck,
    SvgIconComponent, Visibility, Work} from "@mui/icons-material";

export const Icons: {[key: string]: SvgIconComponent} = {
    contains: Archive,
    default: Visibility,
    desires: Favorite,
    enemy: PanTool,
    friend: FavoriteBorder,
    group: People,
    item: Work,
    member: PlaylistAddCheck,
    person: Person,
    place: Place,
    possesses: Luggage
};