import {Link, Typography} from "@mui/material";
import {ModalDialog} from "./ModalDialog";

export function Pending() {

    return (
        <ModalDialog>
            <Typography>You are authenticated but do not currently have permission to view the database.</Typography>
            <Typography component="span">You can request permission from </Typography>
            <Typography component="span">
                <Link href="mailto:alex@logjam.co.uk" target="_blank" rel="noopener">alex@logjam.co.uk</Link>
            </Typography>
        </ModalDialog>
    );
}