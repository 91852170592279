import BorderedBox from "../BorderedBox";
import {Box, MenuItem, TextField} from "@mui/material";
import {ChangeEventHandler} from "react";

interface IPerson {
    editable: boolean
    onEntityChange: (member: string, value: any) => void
    person: any
}
export default function PersonDetails({editable, onEntityChange, person}: IPerson) {
    const handleGenderChange:ChangeEventHandler<HTMLInputElement> = ({target}) => {
        onEntityChange("gender", target.value);
    };

    return (
        <BorderedBox label="Person details" sx={{flexGrow: 1}}>
            <Box display="flex" flexDirection="column" flexGrow={1} m={1}>
                <Box display="flex">
                    <TextField disabled={!editable} onChange={handleGenderChange} label="Gender" select size="small"
                               sx={{flexGrow: 1}} value={person.gender || ""}>
                        <MenuItem disabled value=""><em>None</em></MenuItem>
                        <MenuItem value="masculine">Masculine</MenuItem>
                        <MenuItem value="feminine">Feminine</MenuItem>
                    </TextField>
                </Box>
            </Box>
        </BorderedBox>
    );
}

