import {Parameter} from "./parameter";
import {ParameterString} from "./parameterString";
import {ParameterNumber} from "./parameterNumber";
import {ParameterBoolean} from "./parameterBoolean";
import {ParameterArray} from "./parameterArray";
import {ParameterFree} from "./parameterFree";

export class Factory {
    private static parameterTypes: {[key: string]: any} = {
        string: ParameterString,
        number: ParameterNumber,
        boolean: ParameterBoolean,
        array: ParameterArray,
        free: ParameterFree
    }
    public static Create(data: any): Parameter {
        try {
            const parameter = new Factory.parameterTypes[data.type](data) as Parameter;

            parameter.Load(data);
            return parameter;
        } catch (exception) {
            console.error(`Failed to create parameter with this data: ${JSON.stringify(data)}`);
            throw exception;
        }
    }
}
