import {combineReducers, configureStore} from '@reduxjs/toolkit'
import firebase from "./slices/firebase";
import mode from "./slices/mode";
import user from "./slices/user";

const reducer = combineReducers({
    firebase,
    mode,
    user,
});

export const store = configureStore({reducer});