import {TAliases} from "./entities/entities";

export type TSeverity = "debug" | "info" | "warn" | "error"
type TDelegate = (log: ILogEntry) => void;

export interface ILogEntry {
    severity: TSeverity
    timestamp: number
    text: string
}
export class Logger {
    public aliases: TAliases = {};
    private delegates: TDelegate[] = [];
    private log: ILogEntry[] = [];

    Clear() {
        this.log.length = 0;
    }
    GetLogs(severity: TSeverity[], max?: number) {
        return this.log.filter(log => severity.includes(log.severity)).slice(0, max || this.log.length);
    }
    LogDebug(text: string) {
        this.Log("debug", text)
    }
    LogInfo(text: string) {
        this.Log("info", text)
    }
    LogWarn(text: string) {
        this.Log("warn", text)
    }
    LogError(text: string) {
        this.Log("error", text)
    }
    RegisterDelegate(delegate: TDelegate) {
        this.delegates.push(delegate);
    }
    UnregisterDelegate(delegate: TDelegate) {
        const index = this.delegates.indexOf(delegate);
        
        if (index >= 0) {
            this.delegates.splice(index, 1);
        }
    }

    private Log(severity: TSeverity, text: string) {
        Object.entries(this.aliases).forEach(([key, alias]) => text = text.replaceAll(key, alias));

        const timestamp = Date.now();
        const log = {severity, text, timestamp}

        this.log.push(log);
        this.delegates.forEach(delegate => delegate(log));
    }
}