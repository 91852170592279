import {Box, Typography} from "@mui/material";
import {connect} from "react-redux";
import {update as updateMode} from "../../redux/slices/mode";
import {useEffect} from "react";

interface IWelcome {
    updateMode: typeof updateMode
}
function Welcome({updateMode}: IWelcome) {
    useEffect(() => {
        const timeout = setTimeout(() => {
            updateMode({update: { name: "projects", meta: {}}});
        }, 100);

        return () => clearTimeout(timeout);
    }, [updateMode]);
    return (
        <Box display="flex">
            <Typography variant="h6">Welcome to Aristotle</Typography>
        </Box>
    );
}
const mapStateToProps = (store: never) => ({
});

export default connect(mapStateToProps, {
    updateMode
})(Welcome);
