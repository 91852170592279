import {IFirebaseContext} from "./IFirebaseContext";
import {Dispatch} from "react";
import {Database, get as firebaseGet, getDatabase, off, onValue, push as firebasePush, ref, remove as firebaseRemove,
    set as firebaseSet} from "@firebase/database";
import {remove, update} from "../../redux/slices/firebase";

export type OnUpdate = (update: any) => void
export class FirebaseContext implements IFirebaseContext {

    protected readonly tracking: {[path: string]: string[]} = {};
    protected readonly dispatch: Dispatch<any>
    protected readonly database: Database;

    public constructor(dispatch: Dispatch<any>) {
        this.dispatch = dispatch;
        this.database = getDatabase();
    }
    protected handleFirebaseException(command: string, exception: Error) {
        console.error(command);
        console.error(exception.message);
    }
    public get(path: string): Promise<unknown> {
        return firebaseGet(ref(this.database, path))
            .catch(exception => this.handleFirebaseException(`Push ${path}`, exception))
            .then(snapshot => snapshot ? snapshot.val() : null);
    }
    public push(path: string, value: unknown): Promise<string | null> {
        return firebasePush(ref(this.database, path), value)
            .catch(exception => this.handleFirebaseException(`Push ${path}`, exception))
            .then(reference => reference ? reference.key : null);
    }
    public remove(path: string) {
        return firebaseRemove(ref(this.database, path))
            .catch(exception => this.handleFirebaseException(`Remove ${path}`, exception));
    };
    public set(path: string, value: unknown) {
        return firebaseSet(ref(this.database, path), value)
            .catch(exception => this.handleFirebaseException(`Set ${path}`, exception))
    };
    public track(path: string, onUpdate: OnUpdate) {
        onValue(ref(this.database, path), snapshot => {
            onUpdate(snapshot.val());
        });
    };
    public trackRedux(path: string, store: string[] = [path]) {
        this.tracking[path] = store;
        onValue(ref(this.database, path), snapshot => {
            this.dispatch(update({store, update: snapshot.val()}));
        });
    };
    public untrack(path: string) {
        off(ref(this.database, path));
        if (this.tracking[path]) {
            this.dispatch(remove({remove: this.tracking[path]}));
        }
        delete this.tracking[path];
    };
}