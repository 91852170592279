import {Entity} from "./entity";

type TGender = "masculine" | "feminine"
export class Person extends Entity {
    protected gender: TGender = "masculine";

    Load(data: any) {
        super.Load(data);
        this.gender = data.gender || "masculine";
    }
    Save() {
        return {
            ...super.Save(),
            gender: this.gender
        };
    }

    Describe(format: string): string {
        if (format) {
            switch (format) {
                case "heShe":
                    return this.gender === "masculine" ? "he" : "she";
                case "himHer":
                    return this.gender === "masculine" ? "him" : "her";
                case "hisHer":
                    return this.gender === "masculine" ? "his" : "her";
                case "possessive":
                    return this.name.charAt(this.name.length - 1) === "s" ? `${this.name}'` : `${this.name}'s`;
                default:
                    return `{unknown format string "${format}" passed to ${this.name}}`
            }
        } else {
            return this.name;
        }
    }
}