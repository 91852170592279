export class Entity {
    public readonly type: string;
    protected name: string = "";
    protected properties: string[] = [];

    constructor(data: {type: string}) {
        this.type = data.type;
    }
    Describe(format?: string): string {
        return this.name;
    }
    Load(data: any) {
        this.name = data.name || "";
        this.properties = data.properties || [];
    }
    Save() {
        return {
            name: this.name,
            type: this.type,
            properties: this.properties
        };
    }
}