import {Hypothesis} from "./hypothesis";
import {Logger} from "./logger";
import {PredicateDomain} from "./predicates/predicateDomain";
import {Factory} from "./predicates/factory";
import {Mutation} from "./mutations/mutation";
import {TAliases} from "./entities/entities";

type Predicates = {[type: string]: {[group: string]: PredicateDomain[]}}
export class Domain {
    private predicates: Predicates = {};

    public constructor(private logger?: Logger) {
    }

    public Clear() {
        this.predicates = {};
    }
    public Load(predicates: {[key: string]: any}) {
        Object.values(predicates).map(Factory.Create).forEach(predicate => {
            const group = predicate.DomainGroup();

            if (this.predicates[predicate.type] === undefined) {
                this.predicates[predicate.type] = {};
            }
            if (this.predicates[predicate.type][group] === undefined) {
                this.predicates[predicate.type][group] = [];
            }
            this.predicates[predicate.type][group].push(predicate);
        });
    }
    public Mutate(mutation: Mutation, hypothesis: Hypothesis) {
        const concrete = mutation.Bind(hypothesis);
        
        if (concrete) {
            const group = concrete.DomainGroup();
            const predicates = this.predicates[concrete.type][group] || [];
            const indexOf = predicates.findIndex(predicate => predicate.Unifies(concrete, hypothesis));

            if (mutation.type === "add" && indexOf === -1) {
                predicates.push(concrete);
            } else if (mutation.type === "delete" && indexOf !== -1) {
                predicates.splice(indexOf, 1);
            }
            this.predicates[concrete.type][group] = predicates;
        }
    }
    public Unify(test: PredicateDomain, hypotheses: Hypothesis []): boolean {
        const unified: Hypothesis[] = [];

        for (let hypothesis of hypotheses) {
            const group = test.DomainGroup();

            for (let predicate of this.predicates[test.type][group] || []) {
                const working = new Hypothesis(hypothesis);

                this.logger?.LogDebug(`Testing ${test.describe()} against ${predicate.describe()}`);
                if (predicate.Unifies(test, working)) {
                    this.logger?.LogInfo(`${test.describe()} unifies with ${predicate.describe()} giving ${working}`);
                    unified.push(working);
                }
            }
        }
        hypotheses.splice(0, hypotheses.length, ...unified);
        return unified.length > 0;
    }
    public describe = (aliases: TAliases) => {
        const description: string[] = [];

        Object.values(this.predicates).map(predicateGroups =>
            Object.values(predicateGroups).map(predicates =>
                predicates.forEach(predicate => description.push(predicate.describe(aliases)))));
        return description;
    }
}