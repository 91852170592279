import {Action} from "./action";
import {Plan} from "./plan";

export class Critic {
    private actions: Action[] = [];

    public GetScore(action: Action): number {
        const age = this.actions.reverse().indexOf(action);

        if (age === -1) {
            return action.score;
        } else {
            const decay = action.score * Math.pow(action.decay, age);

            return action.score - decay;
        }
    }
    public ExecutedPlan(plan: Plan) {
        this.actions.push(plan.GetAction());
    }
    public Sort(actions: Action[]): Action[] {
        return actions.sort((lhs, rhs) => this.GetScore(rhs) - this.GetScore(lhs));
    }
}
