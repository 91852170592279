import {Hypothesis} from "../hypothesis";
import {Predicate} from "./predicate";
import {Domain} from "../domain";

export abstract class PredicateDomain extends Predicate {
    public UnifyDomain(domain: Domain, hypotheses: Hypothesis[]): boolean {
        return domain.Unify(this, hypotheses);
    }

    public abstract Bind(hypothesis: Hypothesis): PredicateDomain;
    public abstract Unifies(predicate: PredicateDomain, hypothesis: Hypothesis): boolean;
    public abstract DomainGroup(): string;
}
