import {Box, Divider, IconButton, List, ListItemButton, ListItemIcon, ListItemText, MenuItem, TextField, useTheme}
    from "@mui/material";
import {Factory} from "../../../../aristotle/predicates/factory";
import {ChangeEventHandler, createElement, useEffect, useState} from "react";
import {Add, Delete} from "@mui/icons-material";
import Predicate from "./Predicate";
import {PushId} from "../../../../utility/pushId";
import {selectEntities, selectProject} from "../../../../redux/selectors/firebase";
import {connect} from "react-redux";
import {TFirebaseTable} from "../../../../redux/slices/firebase";
import {Icons} from "../icons";
import {SetPredicateParameters as SetEntityPredicateParameters} from "./PredicateEntityDetails";
import {SetPredicateParameters as SetRelationshipPredicateParameters} from "./PredicateRelatesDetails";

interface IPredicates {
    data: any
    editable: boolean
    entities: TFirebaseTable
    free?: string []
    onChange: (predicate: any) => void
    project: TFirebaseTable
}
function Predicates({data, editable, entities, free, onChange, project}: IPredicates) {
    const theme = useTheme();
    const styles = {
        predicates: {
            flexGrow: 1,
            overflowY: "auto",
            '&::-webkit-scrollbar': {
                width: '0.4em'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
                borderRadius: '0.4em'
            }
        },
        type: {
            flexGrow: 1,
            margin: theme.spacing(1)
        }
    };
    const [addType, setAddType] = useState("");
    const [selected, setSelected] = useState<string>("");
    const handleAddPredicate = () => {
        const predicate = Factory.Create({type: addType});
        const key = PushId();

        if (addType === "entity") {
            SetEntityPredicateParameters(predicate, project, free);
        } else if (addType === "relates") {
            SetRelationshipPredicateParameters(predicate, project, free);
        }
        onChange({
            ...data,
            [key]: predicate.Save()
        });
        setSelected(key);
    };
    const handlePredicateChange = (key: string) => (predicate: any) => {
        onChange({
            ...data,
            [key]: predicate
        });
    }
    const handlePredicateClick = (key: string) => () => setSelected(key);
    const handlePredicateDeleteClick = () => {
        if (selected) {
            const changed = {...data};

            delete changed[selected];
            setSelected("");
            onChange(changed);
        }
    };
    const handleTypeChange:ChangeEventHandler<HTMLInputElement> = ({target}) => {
        setAddType(target.value);
    }
    const aliases = Object.fromEntries(Object.entries(entities).map(([key, entity]) => [key, entity.name]));
    const predicateNames: {[key: string]: string} = Object.fromEntries(
        Object.entries(data).map(([key, predicate]: [string, any]) =>
            [key, Factory.Create(predicate).describe(aliases)]));
    const sortedKeys = Object.keys(predicateNames).sort((lhs, rhs) =>
        predicateNames[lhs].localeCompare(predicateNames[rhs]));

    useEffect(() => {
        if (! selected && Object.keys(data).length) {
            setSelected(Object.keys(data)[0]);
        }
    }, [data, selected]);
    return (
        <Box display="flex" height="100%">
            <Box display="flex" flexDirection="column" height="100%" m={1} width={200}>
                <Box display="flex">
                    <TextField disabled={!editable} label="Type" onChange={handleTypeChange} select
                               size="small" sx={styles.type} value={addType}>
                        <MenuItem value=""><em>None</em></MenuItem>
                        <MenuItem value="and">And</MenuItem>
                        <MenuItem value="or">Or</MenuItem>
                        <MenuItem value="not">Not</MenuItem>
                        <Divider/>
                        <MenuItem value="entity">Entity</MenuItem>
                        <MenuItem value="relates">Relationship</MenuItem>
                        <MenuItem value="true">True</MenuItem>
                    </TextField>
                    <Box>
                        <IconButton disabled={!editable || addType === ""} onClick={handleAddPredicate}
                                    sx={{marginTop: 1}}>
                            <Add/>
                        </IconButton>
                    </Box>
                </Box>
                <Box sx={styles.predicates}>
                    <List dense>
                        {sortedKeys.map(key =>
                            <ListItemButton disableGutters key={key} onClick={handlePredicateClick(key)}
                                            selected={selected === key}>
                                <ListItemIcon>
                                    {createElement(Icons[data[key].entityType?.value] ||
                                        Icons[data[key].relationship?.value] || Icons.default)}
                                </ListItemIcon>
                                <ListItemText primary={predicateNames[key]}/>
                            </ListItemButton>
                        )}
                    </List>
                </Box>
            </Box>
            {selected && data[selected] &&
                <Box display="flex" flexGrow={1} m={1}>
                    <Box display="flex" flexDirection="column" flexGrow={1}>
                        <Predicate data={data[selected]} editable={editable} free={free || []}
                                   onChange={handlePredicateChange(selected)}/>
                    </Box>
                    <Box>
                        <IconButton disabled={!editable} onClick={handlePredicateDeleteClick}>
                            <Delete/>
                        </IconButton>
                    </Box>
                </Box>
            }
        </Box>
    );
}
const mapStateToProps = (store: never) => ({
    project: selectProject(store),
    entities: selectEntities(store)
});

export default connect(mapStateToProps, {
})(Predicates);
