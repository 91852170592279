import Authentication from "../authentication/Authentication";
import FirebaseProvider from "../firebase/FirebaseProvider";
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {responsiveFontSizes} from "@mui/material";
import Portal from "./Portal";
import {Demo} from "../demo/Demo";

declare global {
    namespace NodeJS {
        interface ProcessEnv {
            REACT_APP_VERSION: string;
        }
    }
}

const customTheme = {
};

export function App() {
    const theme = responsiveFontSizes(createTheme(customTheme));
    const url = new URL(window.location.href);
    const path = url.pathname;
    const project = url.searchParams.get("project");

    return (
            <ThemeProvider theme={theme}>
                <FirebaseProvider>
                    {path === "/demo" && project ?
                        <Demo project={project}/>
                        :
                        <Authentication>
                            <Portal/>
                        </Authentication>
                    }
                </FirebaseProvider>
            </ThemeProvider>
    );
}
