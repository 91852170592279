import {Predicate} from "./predicate";
import {Domain} from "../domain";
import {Hypothesis} from "../hypothesis";

export class PredicateTrue extends Predicate {
    Load(data: any): void {
    }

    Save(): any {
        return {
            type: "true"
        };
    }

    UnifyDomain(domain: Domain, hypotheses: Hypothesis[]): boolean {
        return true;
    }

    describe(aliases?: { [p: string]: string }): string {
        return "True";
    }
}
