import {Box, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography}
    from "@mui/material";
import {connect} from "react-redux";
import {selectProjects} from "../../redux/selectors/firebase";
import {TProjects} from "../../redux/slices/firebase";
import {selectUserDetails, selectUserId} from "../../redux/selectors/user";
import {IUserDetails} from "../../redux/slices/user";
import {update as updateFirebase} from "../../redux/slices/firebase";
import {update as updateMode} from "../../redux/slices/mode";
import {Add, ContentCopy, Delete} from "@mui/icons-material";
import {MouseEventHandler, useContext, useEffect, useState} from "react";
import {FirebaseContext} from "../firebase/FirebaseProvider";
import ModePortal from "./ModePortal";

const projectTables = ["actions", "entities", "predicates"];

interface IProjects {
    projects: TProjects
    updateFirebase: typeof updateFirebase
    updateMode: typeof updateMode
    userDetails: IUserDetails
    userId: string
}
function Projects({projects, updateFirebase, updateMode, userDetails, userId}: IProjects) {
    const [disableUpdates, setDisableUpdates] = useState(false);
    const firebase = useContext(FirebaseContext);
    const handleAddProject = () => {
        firebase.push("projects", {
            name: "New Project",
            owner: userId,
            visibility: "private"
        }).catch(console.error);
    };
    const handleCopyProject: (project: string) => MouseEventHandler = (project: string) => event => {
        event.stopPropagation();
        setDisableUpdates(true);
        firebase.get(`projects/${project}`).then((value: any) => firebase.push(`projects`, {
            ...value,
            name: `Copy of ${value.name}`,
            owner: userId,
            visibility: "private"
        })).then(newProject => {
                Promise.all(projectTables.map(table =>
                    firebase.get(`${table}/${project}`).then(value => firebase.set(`${table}/${newProject}`, value))
                ))
            }
        ).then(() => setDisableUpdates(false));
    };
    const handleDeleteProject: (project: string) => MouseEventHandler = (project: string) => event => {
        event.stopPropagation();
        setDisableUpdates(true);
        Promise.all([...projectTables, "projects"].map(table =>
            firebase.remove(`${table}/${project}`)
        )).then(() => setDisableUpdates(false));
    };
    const handleSelectProject: MouseEventHandler<HTMLLIElement> = ({currentTarget}) => {
        const project = currentTarget.dataset.key;

        updateMode({update: {name: "play", meta: {project}}});
    };

    useEffect(() => {
        firebase.track("projects", (projects: TProjects) => {
            updateFirebase({
                update: Object.fromEntries(Object.entries(projects).filter(([key, project]) =>
                    project.visibility === "public" || userDetails.roles.admin || project.owner === userId ||
                    (userDetails.roles.projects && userDetails.roles.projects[key]?.viewer)
                )),
                store: ["projects"]
            });
        });
        return () => firebase.untrack("projects");
    }, [firebase, userDetails.roles.admin, userDetails.roles.projects, userId, updateFirebase]);
    return (
        <ModePortal>
            <Toolbar sx={{marginRight: 4}}>
                <Typography flexGrow={1} variant="h6">Projects</Typography>
                {userDetails.roles.editor &&
                    <IconButton disabled={disableUpdates} onClick={handleAddProject} title="Add project">
                        <Add/>
                    </IconButton>
                }
            </Toolbar>
            <List>
                {Object.keys(projects).map(project =>
                    <ListItem data-key={project} key={project} onClick={handleSelectProject}>
                        <ListItemButton disabled={disableUpdates}>
                            <ListItemText primary={projects[project].name}/>
                            {projects[project].owner === userId &&
                                <Box>
                                    <ListItemIcon onClick={handleDeleteProject(project)} title="Delete project">
                                        <Delete/>
                                    </ListItemIcon>
                                </Box>
                            }
                            <ListItemIcon onClick={handleCopyProject(project)} title="Copy project">
                                <ContentCopy/>
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                )}
            </List>
        </ModePortal>
    );
}
const mapStateToProps = (store: never) => ({
    projects: selectProjects(store),
    userDetails: selectUserDetails(store),
    userId: selectUserId(store),
});

export default connect(mapStateToProps, {
    updateFirebase,
    updateMode
})(Projects);
