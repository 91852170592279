import {Hypothesis} from "../hypothesis";
import {PredicateDomain} from "./predicateDomain";
import {Factory as ParameterFactory} from "../parameters/factory";
import {Factory as PredicateFactory} from "../predicates/factory";
import {Parameter} from "../parameters/parameter";

export class PredicateRelates extends PredicateDomain {
    protected object?: Parameter;
    protected relationship?: Parameter;
    protected subject?: Parameter;

    public describe(aliases: {[key: string]: string} = {}): string {
        const getAlias = (key?: Parameter) => {
            if (key) {
                return aliases[key.toString()] || key.toString();
            } else {
                return "Undefined";
            }
        }
        return `${getAlias(this.object)} ${this.relationship} ${getAlias(this.subject)}`
    }
    public DomainGroup(): string {
        return this.relationship && this.relationship.type === "string" ? this.relationship.toString() : "";
    }

    public Load(data: any) {
        this.object = ParameterFactory.Create(data.object || {type: "string"});
        this.relationship = ParameterFactory.Create(data.relationship || {type: "string"});
        this.subject = ParameterFactory.Create(data.subject || {type: "string"});
    }
    public Save(): any {
        return {
            object: this.object?.Save() ?? {type: "string"},
            relationship: this.relationship?.Save() ?? {type: "string"},
            subject: this.subject?.Save() ?? {type: "string"},
            type: this.type
        };
    }
    public Bind(hypothesis: Hypothesis): PredicateRelates {
        const bound = PredicateFactory.Create({type: this.type}) as PredicateRelates;

        bound.object = this.object!.Bind(hypothesis);
        bound.relationship = this.relationship!.Bind(hypothesis);
        bound.subject = this.subject!.Bind(hypothesis);
        return bound;
    }
    public Unifies(predicate: PredicateDomain, hypothesis: Hypothesis) {
        if (this.type === predicate.type) {
            const working = new Hypothesis(hypothesis);
            const test = predicate as PredicateRelates;

            if (this.object!.Unifies(test.object!, working) &&
                this.relationship!.Unifies(test.relationship!, working) &&
                this.subject!.Unifies(test.subject!, working)) {
                hypothesis.Bind(working);
                return true;
            }
        }
        return false;
    }
}