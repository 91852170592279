import BorderedBox from "../BorderedBox";
import {Box, IconButton, List, ListItem, TextField} from "@mui/material";
import {ChangeEventHandler} from "react";
import {Add, Delete} from "@mui/icons-material";
import PersonDetails from "./PersonDetails";
import {selectProject} from "../../../../redux/selectors/firebase";
import {connect} from "react-redux";
import {TFirebaseTable} from "../../../../redux/slices/firebase";

interface IEntity {
    editable: boolean
    entity: any
    onEntityChange: (member: string, value: any) => void
    project: TFirebaseTable
}
function Entity({editable, entity, onEntityChange, project}: IEntity) {
    const handleMemberChange = (member: string):ChangeEventHandler<HTMLInputElement> =>
        ({target}) => {
            onEntityChange(member, target.value);
        };
    const handleAddProperty = () => {
        onEntityChange("properties", [...(entity.properties || []), "New property"]);
    };
    const handleDeleteProperty = (index: number) => () => {
        const properties = [...entity.properties];

        properties.splice(index, 1);
        onEntityChange("properties", properties);
    };
    const handlePropertyChange = (index: number): ChangeEventHandler<HTMLInputElement> =>
        ({target}) => {
            const properties = [...entity.properties];
            
            properties[index] = target.value;
            onEntityChange("properties", properties);
        };

    return (
        <BorderedBox label={project.entityTypes[entity.type]}>
            <Box display="flex" flexDirection="column">
                <TextField disabled={!editable} label="Name" onChange={handleMemberChange("name")}
                           size="small" sx={{m: 1}} value={entity.name || ""}/>
                <Box display="flex" m={1}>
                    <Box flexGrow={1}>
                        <BorderedBox label="Properties">
                            <List>
                                {(entity.properties || []).map((property: string, index: number) => (
                                    <ListItem key={index}>
                                        <Box flexGrow={1} display="flex">
                                            <Box flexGrow={1}>
                                                <TextField disabled={!editable} fullWidth label="Property"
                                                           onChange={handlePropertyChange(index)}
                                                           size="small" value={property}/>
                                            </Box>
                                            <IconButton disabled={!editable} size="small"
                                                        onClick={handleDeleteProperty(index)}>
                                                <Delete/>
                                            </IconButton>
                                        </Box>
                                    </ListItem>
                                ))}
                            </List>
                        </BorderedBox>
                    </Box>
                    <Box>
                        <IconButton disabled={!editable} onClick={handleAddProperty} sx={{marginTop: 1}}>
                            <Add/>
                        </IconButton>
                    </Box>
                </Box>
                <Box display="flex" m={1}>
                    {entity.type === "person" && <PersonDetails editable={editable} onEntityChange={onEntityChange} person={entity}/>}
                </Box>
            </Box>
        </BorderedBox>
    );
}
const mapStateToProps = (store: never) => ({
    project: selectProject(store)
});

export default connect(mapStateToProps, {
})(Entity);
