import {Provider} from 'react-redux';
import {compose} from 'redux';
import {App} from './components/framework/App';
import firebaseConfigDevelop from './firebase/development.json';
import {store} from './redux/store';
import {initializeApp} from "firebase/app";
import {createRoot} from "react-dom/client";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import version from "./data/version.json";

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

Sentry.init({
    environment: window.location.hostname === "localhost" ? "development" : "production",
    release: `aristotle@${version.version}`,
    dsn: "https://afd5327a205246bd8172a336d57836fe@o1412937.ingest.sentry.io/6752504",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1
});
initializeApp(firebaseConfigDevelop);

const rootElement = document.getElementById("root");

if (rootElement) {
    const root = createRoot(rootElement);

    root.render(
        <Provider store={store}>
            <App/>
        </Provider>
    );
}