import {Entity} from "./entity";
import {Person} from "./person";

export class Factory {
    private static entityTypes: {[key: string]: any} = {
        default: Entity,
        person: Person
    }

    public static Create(data: any): Entity {
        try {
            const entity = new (Factory.entityTypes[data.type] || Factory.entityTypes.default)(data);

            entity.Load(data);
            return entity;
        } catch (exception) {
            console.error(`Failed to create entity with this data: ${JSON.stringify(data)}`);
            throw exception;
        }
    }
}