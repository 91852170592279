import {connect} from "react-redux";
import {Box, Button, IconButton, List, ListItemButton, ListItemText, Typography, useTheme} from "@mui/material";
import {Add, Delete} from "@mui/icons-material";
import {useCallback, useContext, useEffect, useState} from "react";
import {FirebaseContext} from "../../../firebase/FirebaseProvider";
import {selectMeta} from "../../../../redux/selectors/mode";
import {IModeMeta} from "../../../../redux/slices/mode";
import {EditAction} from "./EditAction";
import {selectActions} from "../../../../redux/selectors/firebase";
import {TFirebaseTable} from "../../../../redux/slices/firebase";
import {Action, IActionData} from "../../../../aristotle/action";
import {PushId} from "../../../../utility/pushId";

interface IEditActions {
    actions: TFirebaseTable
    editable: boolean
    modeData: IModeMeta
}
function Actions ({actions, editable, modeData}: IEditActions) {
    const theme = useTheme();
    const styles = {
        actions: {
            flexGrow: 1,
            overflowY: "auto",
            '&::-webkit-scrollbar': {
                width: '0.4em'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
                borderRadius: '0.4em'
            }
        },
        content: {
            display: "flex",
            margin: theme.spacing(1),
            height: "calc(100vh - 216px)"
        }
    };
    const firebase = useContext(FirebaseContext);
    const [saving, setSaving] = useState(false);
    const [selected, setSelected] = useState("");
    const [working, setWorking] = useState<IActionData>();
    const handleActionChange = useCallback((member: string, value: any) => {
        setWorking(working => ({
            ...working as IActionData,
            [member]: value
        }));
    }, []);
    const handleAddAction = useCallback(() => {
        const action = new Action({
            description: "Describe action",
            name: "New Action",
            precondition: { type: "true" },
            decay: 0,
            score: 0,
            update: {}
        });
        const key = PushId();

        setWorking(action.Save());
        setSelected(key);
    }, []);
    const handleDeleteAction = useCallback(() => {
        if (selected) {
            firebase.remove(`actions/${modeData.project}/${selected}`).then(() => {
                setSelected("");
                setWorking(undefined);
            });
        }
    }, [firebase, modeData.project, selected]);
    const handleSaveClick = useCallback(() => {
        if (selected) {
            setSaving(true);
            firebase.set(`actions/${modeData.project}/${selected}`, working).then(() =>
                setSaving(false));
        }
    }, [firebase, modeData.project, selected, working]);
    const handleSelectAction = (key: string) => () => {
        setSelected(key);
        setWorking(actions[key]);
    }
    const saveDisabled = !editable || JSON.stringify(actions[selected]) === JSON.stringify(working) || saving;

    useEffect(() => {
        if (! selected && Object.keys(actions).length) {
            setSelected(Object.keys(actions)[0]);
            setWorking(actions[selected]);
        }
    }, [actions, selected]);
    return (
        <Box display="flex" flexDirection="column" m={1}>
            <Box sx={styles.content}>
                <Box display="flex" flexDirection="column" m={1} width={200}>
                    <Box display="flex">
                        <Typography flexGrow={1} variant="h6">Actions</Typography>
                        <IconButton disabled={!editable} edge="end" onClick={handleAddAction}>
                            <Add/>
                        </IconButton>
                    </Box>
                    <Box sx={styles.actions} flexGrow={1}>
                        <List>
                            {actions && Object.keys(actions)  
                                .sort((lhs, rhs) =>
                                    actions[lhs].name.localeCompare(actions[rhs].name))
                                .map(key =>
                                    <ListItemButton data-action={key} key={key} onClick={handleSelectAction(key)}
                                                    selected={selected === key}>
                                        <ListItemText primary={actions[key].name}/>
                                    </ListItemButton>
                            )}
                        </List>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column" flexGrow={1}>
                    {working &&
                        <EditAction action={working} editable={editable} onChange={handleActionChange}/>
                    }
                </Box>
                <Box>
                    <IconButton disabled={!editable || !selected} edge="end" onClick={handleDeleteAction}>
                        <Delete/>
                    </IconButton>
                </Box>
            </Box>
            <Box display="flex" justifyContent="right" m={1}>
                <Button disabled={saveDisabled} onClick={handleSaveClick} variant="contained">Save</Button>
            </Box>
        </Box>
    );
}
const mapStateToProps = (store: never) => ({
    actions: selectActions(store) || {},
    modeData: selectMeta(store)
});

export default connect(mapStateToProps, {
})(Actions);
