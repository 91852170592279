import {Box, Button, Typography} from "@mui/material";
import {AutoScroll} from "../AutoScroll";
import {Narrator} from "../../../aristotle/narrator";
import { Logger } from "../../../aristotle/logger";
import {selectPredicates} from "../../../redux/selectors/firebase";
import {connect} from "react-redux";
import {TFirebaseTable} from "../../../redux/slices/firebase";
import {useReducer} from "react";

interface IPlayDomain {
    logger: Logger
    narrator: Narrator
    predicates: TFirebaseTable
}
function PlayDomain({logger, narrator, predicates}: IPlayDomain) {
    const styles = {
        content: {
            height: "100%",
        },
        domain: {
            height: "calc(100% - 46px)"
        },
    }
    const forceUpdate = useReducer(() => ({}), {})[1] as () => void;
    const handleResetDomain = () => {
        narrator.ResetDomain(predicates);
        forceUpdate();
        logger.LogInfo("Reset domain");
    }

    return (
        <Box sx={styles.content}>
            <Box display="flex">
                <Typography component="span" flexGrow={1} variant="h6">Domain</Typography>
                <Button onClick={handleResetDomain} variant="contained"
                        size="small" sx={{m:1}}>
                    Reset
                </Button>
            </Box>
            <Box  sx={styles.domain}>
                <AutoScroll content={narrator.describe()}/>
            </Box>
        </Box>
    );
}
const mapStateToProps = (store: never) => ({
    predicates: selectPredicates(store),
});

const connected = connect(mapStateToProps, {})(PlayDomain);

export {connected as PlayDomain};