import {createDeepSlice} from "./base";

interface IRoles {
    admin: boolean
    editor: boolean
    viewer: boolean
}
export interface IProjectRoles extends IRoles {
    projects: {[key: string]: IRoles}
}
export interface IUserDetails {
    name: string
    roles: IProjectRoles
}
export interface IUserState {
    details?: IUserDetails
    userId?: string
}

const slice = createDeepSlice<IUserState>("user", {});

export const {remove, update} = slice.actions;
export default slice.reducer;