import {connect} from "react-redux";
import Predicates from "./Predicates";
import {useContext, useEffect, useState} from "react";
import {selectMeta} from "../../../../redux/selectors/mode";
import {IModeMeta} from "../../../../redux/slices/mode";
import {FirebaseContext} from "../../../firebase/FirebaseProvider";
import {Box, Button, useTheme} from "@mui/material";
import {TFirebaseTable} from "../../../../redux/slices/firebase";
import {selectPredicates} from "../../../../redux/selectors/firebase";

interface IDomainPredicates {
    editable: boolean
    modeData: IModeMeta
    predicates: TFirebaseTable
}
function DomainPredicates({editable, modeData, predicates}: IDomainPredicates) {
    const theme = useTheme();
    const styles = {
        content: {
            display: "flex",
            flexDirection: "column",
            margin: theme.spacing(1),
            height: "calc(100vh - 216px)"
        }
    };
    const firebase = useContext(FirebaseContext);
    const [saving, setSaving] = useState(false);
    const [working, setWorking] = useState<TFirebaseTable>({});
    const handleChangePredicates = (data: any) => {
        setWorking(data);
    };
    const handleSaveClick = () => {
        setSaving(true);
        firebase.set(`predicates/${modeData.project}`, working).then(() =>
            setSaving(false));
    }
    const saveDisabled = !editable || JSON.stringify(predicates) === JSON.stringify(working) || saving;

    useEffect(() => {
        setWorking(predicates || {});
    }, [predicates]);
    return (
        <Box display="flex" flexDirection="column">
            <Box sx={styles.content}>
                <Predicates data={working} editable={editable} onChange={handleChangePredicates}/>
            </Box>
            <Box display="flex" justifyContent="right" m={1}>
                <Button disabled={saveDisabled} onClick={handleSaveClick} variant="contained">Save</Button>
            </Box>
        </Box>
    );
}
const mapStateToProps = (store: never) => ({
    modeData: selectMeta(store),
    predicates: selectPredicates(store)
});

export default connect(mapStateToProps, {
})(DomainPredicates);
