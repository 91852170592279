import {selectMeta} from "../../../../redux/selectors/mode";
import {connect} from "react-redux";
import {IModeMeta} from "../../../../redux/slices/mode";
import {Box, Button, IconButton, List, ListItemButton, ListItemIcon, ListItemText, MenuItem, TextField, useTheme}
    from "@mui/material";
import {ChangeEventHandler, createElement, useContext, useEffect, useState} from "react";
import {FirebaseContext} from "../../../firebase/FirebaseProvider";
import {Add, Delete, Visibility} from "@mui/icons-material";
import {Factory as EntityFactory} from "../../../../aristotle/entities/factory";
import {Factory as PredicateFactory} from "../../../../aristotle/predicates/factory";
import {PushId} from "../../../../utility/pushId";
import Entity from "./Entity";
import {selectEntities, selectPredicates, selectProject} from "../../../../redux/selectors/firebase";
import {TFirebaseTable} from "../../../../redux/slices/firebase";
import {Icons} from "../icons";

interface IEntities {
    editable: boolean
    entities: TFirebaseTable
    meta: IModeMeta
    predicates: TFirebaseTable
    project: TFirebaseTable
}
function Entities({editable, entities, meta, predicates, project}: IEntities) {
    const theme = useTheme();
    const styles = {
        content: {
            display: "flex",
            margin: theme.spacing(1),
            height: "calc(100vh - 216px)"
        },
        scrolling: {
            margin: theme.spacing(1),
            overflowY: "auto",
            '&::-webkit-scrollbar': {
                width: '0.4em'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
                borderRadius: '0.4em'
            }
        }
    };
    const firebase = useContext(FirebaseContext);
    const [addEntity, setAddEntity] = useState("");
    const [saving, setSaving] = useState(false);
    const [selected, setSelected] = useState("");
    const [working, setWorking] = useState<TFirebaseTable>({});
    const handleAddEntity = () => {
        const entity = EntityFactory.Create({type: addEntity});
        const key = PushId();

        setWorking(working => ({
            ...working,
            [key]: entity.Save()
        }));
        setSelected(key);
    };
    const handleAddPredicateClick = () => {
        const predicate = PredicateFactory.Create({
            entityKey: {type: "string", value: selected},
            entityType: {type: "string", value: working[selected].type},
            properties: {type: "array", value: working[selected].properties},
            type: "entity",
        });

        setSaving(true);
        firebase.push(`predicates/${meta.project}`, predicate.Save()).then(() => {
            setSaving(false);
        });
    };
    const handleDeleteClick = () => {
        setWorking(working => {
            const update = {...working};

            setSelected("");
            delete update[selected];
            return update;
        });
    };
    const handleEntityClick = (entity: string) => () => {
        setSelected(entity);
    };
    const handleEntityTypeChange:ChangeEventHandler<HTMLInputElement> = ({target}) => {
        setAddEntity(target.value);
    };
    const handleEntityChange = (member: string, value: any) => {
            setWorking(working => ({
                ...working,
                [selected]: {
                    ...working[selected],
                    [member]: value
                }
            }));
        };
    const handleSaveClick = () => {
        setSaving(true);
        firebase.set(`entities/${meta.project}`, working).then(() =>
            setSaving(false));
    }
    const saveDisabled = !editable || JSON.stringify(entities) === JSON.stringify(working) || saving;
    const addPredicateDisabled = selected === "" ||
        Object.values(predicates).find(predicate => predicate.entityKey?.value === selected) !== undefined;
    const sortedKeys = Object.keys(working).sort((lhs, rhs) =>
        working[lhs].name.localeCompare(working[rhs].name));

    useEffect(() => {
        setWorking(entities);
    }, [entities])
    useEffect(() => {
        if (! selected && sortedKeys.length) {
            setSelected(sortedKeys[0]);
        }
    }, [working, selected, sortedKeys]);
    return (
        <Box display="flex" flexDirection="column" m={1}>
            <Box sx={styles.content}>
                <Box display="flex" flexDirection="column" m={1} width={200}>
                    <Box display="flex">
                        <TextField disabled={!editable} onChange={handleEntityTypeChange} label="Add entity type"
                                   select size="small" sx={{flexGrow: 1}} value={addEntity}>
                            <MenuItem value=""><em>None</em></MenuItem>
                            {Object.entries(project.entityTypes).map(([key, entityType]) =>
                                <MenuItem key={key} value={key}>{entityType as string}</MenuItem>
                            )}
                        </TextField>
                        <Box>
                            <IconButton disabled={addEntity === ""} onClick={handleAddEntity}>
                                <Add/>
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={styles.scrolling}>
                        <List dense={true}>
                            {sortedKeys.map(key =>
                                <ListItemButton disableGutters key={key} onClick={handleEntityClick(key)}
                                                selected={selected === key}>
                                    <ListItemIcon>
                                        {createElement(Icons[working[key].type] || Icons.default)}
                                    </ListItemIcon>
                                    <ListItemText primary={working[key].name}/>
                                </ListItemButton>
                            )}
                        </List>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column" flexGrow={1}>
                    {working[selected] &&
                        <Box display="flex">
                            <Box flexGrow={1}>
                                <Entity editable={editable} entity={working[selected]}
                                        onEntityChange={handleEntityChange}/>
                            </Box>
                            <Box display="flex" flexDirection="column">
                                <IconButton disabled={!editable || selected === ""} title="Delete Entity"
                                            onClick={handleDeleteClick}>
                                    <Delete/>
                                </IconButton>
                                <IconButton disabled={!editable || addPredicateDisabled} title="Add Predicate"
                                            onClick={handleAddPredicateClick}>
                                    <Visibility/>
                                </IconButton>
                            </Box>
                        </Box>
                    }
                </Box>
            </Box>
            <Box display="flex" justifyContent="right" m={1}>
                <Button disabled={saveDisabled} onClick={handleSaveClick} variant="contained">Save</Button>
            </Box>
        </Box>        
    );
}
const mapStateToProps = (store: never) => ({
    entities: selectEntities(store),
    meta: selectMeta(store),
    predicates: selectPredicates(store),
    project: selectProject(store)
});

export default connect(mapStateToProps, {
})(Entities);
