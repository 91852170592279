import {ReactNode, useContext, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {getAnalytics, logEvent, setUserId as setAnalyticsUser} from "firebase/analytics";
import {FirebaseContext} from "../firebase/FirebaseProvider";
import Login from "./Login";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {selectUserDetails, selectUserId} from "../../redux/selectors/user";
import {update as updateUser} from "../../redux/slices/user";
import {IUserDetails} from "../../redux/slices/user";
import {Pending} from "./Pending";

type Props = {
    children?: ReactNode
    updateUser: typeof updateUser
    userDetails?: IUserDetails
    userId: string
}
function Authentication ({children, updateUser, userDetails, userId}: Props) {
    const auth = getAuth();
    const firebase = useContext(FirebaseContext);
    const [showLogin, setShowLogin] = useState(false);

    useEffect(() => {
        onAuthStateChanged(auth, (authenticated) => {
            if (authenticated !== null) {
                const analytics = getAnalytics();

                updateUser({update: authenticated.uid, store: ["userId"]});
                setAnalyticsUser(analytics, authenticated.uid);
                logEvent(analytics, "login", {
                    method: authenticated.providerId === "google.com" ? "google" : "email-password"
                });
                if (authenticated.email !== null) {
                    window.localStorage.setItem("emailForSignIn", authenticated.email);
                }
                setShowLogin(false);
            } else {
                setShowLogin(true);
            }
        });
    }, [auth, firebase, updateUser])
    useEffect(() => {
        if (firebase && userId) {
            firebase.track(`users/${userId}`, (update) => {
                updateUser({update, store: ["details"]});
            });
        }
    }, [firebase, updateUser, userId])
    if (showLogin) {
        // Not authenticated
        return <Login/>
    } else if (! userDetails || ! userDetails.roles.viewer) {
        // Authenticated and waiting for user data
        return (
            <Pending/>
        );
    } else {
        // Authenticated
        return (
            <>
                {children}
            </>
        );
    }
}

const mapStateToProps = (store: never) => ({
    userDetails: selectUserDetails(store),
    userId: selectUserId(store)
});

export default connect(mapStateToProps, {
    updateUser
})(Authentication);
