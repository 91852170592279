import {AppBar, Box, Dialog, IconButton, Menu, MenuItem, Toolbar, Typography} from "@mui/material";
import {AccountCircle, Close, Edit, Help, Menu as MenuIcon, MenuBook, Visibility} from "@mui/icons-material";
import {selectMode} from "../../redux/selectors/mode";
import {connect} from "react-redux";
import {IModeState, update as updateMode} from "../../redux/slices/mode";
import {Update} from "../../redux/slices/base";
import {MouseEventHandler, useState} from "react";
import {selectProject} from "../../redux/selectors/firebase";
import {TFirebaseTable} from "../../redux/slices/firebase";
import {getAuth, signOut} from "firebase/auth";
import {selectUserDetails, selectUserId} from "../../redux/selectors/user";
import {IUserDetails} from "../../redux/slices/user";
import {AdminTools} from "../admin/AdminTools";
import version from "../../data/version.json";
import {Markdown} from "./Markdown";

interface INavigation {
    mode: IModeState
    project: TFirebaseTable
    updateMode: Update
    userDetails: IUserDetails
    userId: string
}
function Navigation({mode, project, updateMode, userDetails, userId}: INavigation) {
    const name = project?.name ? `Aristotle: ${project.name}` : "Aristotle";
    const [admin, setAdmin] = useState(false);
    const [changelog, setChangelog] = useState(false);
    const [mainMenuAnchor, setMainMenuAnchor] = useState<HTMLElement>();
    const [userMenuAnchor, setUserMenuAnchor] = useState<HTMLElement>();
    const handleAdminTools = () => {
        setAdmin(true);
        setUserMenuAnchor(undefined);
    }
    const handleCloseChangelog = () => {
        setChangelog(false);
        setUserMenuAnchor(undefined);
    }
    const handleCloseProject: MouseEventHandler<HTMLElement> = ({target}) => {
        updateMode({update: {name: "projects", meta: {}}});
        setMainMenuAnchor(undefined);
    }
    const handleHelpClick = () => {
        updateMode({store: ["meta", "help"], update: {subject: mode.name, open: true}});
    };
    const handleMenuClose = () => {
        setMainMenuAnchor(undefined);
        setUserMenuAnchor(undefined);
    }
    const handleMainMenuOpen: MouseEventHandler<HTMLElement> = ({currentTarget}) =>
        setMainMenuAnchor(currentTarget);
    const handleUserMenuOpen: MouseEventHandler<HTMLElement> = ({currentTarget}) =>
        setUserMenuAnchor(currentTarget);
    const handleSetMode = (mode: string) => () => updateMode({update: mode, store: ["name"]});
    const handleLogout: MouseEventHandler = () => {
        signOut(getAuth()).then(() => {
            setUserMenuAnchor(undefined);
        });
    }
    const modeStyle = (name: string) => ({backgroundColor: mode.name === name ? "primary.dark" : "primary"});
    const editable = project && (project.owner === userId ||
        (userDetails.roles.projects && mode.meta.project && userDetails.roles.projects[mode.meta.project]?.editor));

    return (
        <>
            <AppBar position="fixed">
                <Toolbar variant="dense">
                    <IconButton color="inherit" edge="start" onClick={handleMainMenuOpen} size="large">
                        <MenuIcon/>
                    </IconButton>
                    <Typography flexGrow={1} variant="h6">{name}</Typography>
                    {mode.meta.project &&
                        <Box marginRight={2}>
                            <IconButton color="inherit" onClick={handleSetMode("play")}
                                        title="Play Mode" sx={modeStyle("play")}>
                                <MenuBook/>
                            </IconButton>
                            {editable ?
                                <IconButton color="inherit" onClick={handleSetMode("edit")}
                                            title="Edit Mode" sx={modeStyle("edit")}>
                                    <Edit/>
                                </IconButton> :
                                <IconButton color="inherit" onClick={handleSetMode("edit")}
                                            title="View Mode" sx={modeStyle("edit")}>
                                    <Visibility/>
                                </IconButton>
                            }
                        </Box>
                    }
                    <IconButton color="inherit" onClick={handleHelpClick} size="large" title="Help">
                        <Help/>
                    </IconButton>
                    <IconButton color="inherit" edge="end" onClick={handleUserMenuOpen} size="large"
                                title="User Account">
                        <AccountCircle/>
                    </IconButton>
                    <Menu anchorEl={mainMenuAnchor} onClose={handleMenuClose} open={Boolean(mainMenuAnchor)}>
                        <MenuItem onClick={handleCloseProject}>Close Project</MenuItem>
                    </Menu>
                    <Menu anchorEl={userMenuAnchor} onClose={handleMenuClose} open={Boolean(userMenuAnchor)}>
                        {userDetails.roles.admin &&
                            <MenuItem onClick={handleAdminTools}>Admin Tools</MenuItem>
                        }
                        <MenuItem onClick={handleLogout}>Log Out</MenuItem>
                        <MenuItem onClick={() => setChangelog(true)}>Change Log</MenuItem>
                        <MenuItem disabled>Version: {version.version}</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Toolbar variant="dense"/>
            <Dialog open={admin} onClose={() => setAdmin(false)}>
                <AdminTools/>
            </Dialog>
            <Dialog open={changelog} onClose={handleCloseChangelog}>
                <Box padding={4}>
                    <IconButton onClick={handleCloseChangelog} sx={{position: "absolute", top: 1, right: 1}}>
                        <Close/>
                    </IconButton>
                    <Markdown source={"changelog.md"}/>
                </Box>
            </Dialog>
            </>
    );
}
const mapStateToProps = (store: never) => ({
    mode: selectMode(store),
    project: selectProject(store),
    userDetails: selectUserDetails(store),
    userId: selectUserId(store)
});

export default connect(mapStateToProps, {
    updateMode
})(Navigation);
