import {
    Accordion, AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    FormControlLabel, List, ListItemText,
    MenuItem,
    Switch,
    TextField, Typography,
    useTheme
} from "@mui/material";
import {AutoScroll} from "../AutoScroll";
import {ITestResults, Narrator} from "../../../aristotle/narrator";
import {Plan} from "../../../aristotle/plan";
import {ChangeEventHandler, useEffect, useState} from "react";
import {selectActions} from "../../../redux/selectors/firebase";
import {connect} from "react-redux";
import {TFirebaseTable} from "../../../redux/slices/firebase";
import {Logger} from "../../../aristotle/logger";
import {ExpandMore} from "@mui/icons-material";

interface IPlan {
    actions: TFirebaseTable
    logger: Logger
    narrator: Narrator
}
function PlayPlan({actions, logger, narrator}: IPlan) {
    const theme = useTheme();
    const styles = {
        content: {
            height: "100%",
        },
        legalPlans: {
            width: "30%",
            overflowY: "scroll"
        },
        plans: {
            display: "flex",
            height: "calc(100% - 140px)",
        },
        executedPlans: {
            width: "70%",
            overflowY: "scroll"
        },
        tools: {
            display: "flex",
            justifyContent: "right",
            margin: theme.spacing(1)
        }
    };
    const [action, setAction] = useState("");
    const [actionNames, setActionNames] = useState<{[key: string]: string}>({});
    const [legalPlans, setLegalPlans] = useState<Plan[]>([]);
    const [executedPlans, setExecutedPlans] = useState<string[]>([]);
    const [random, setRandom] = useState(false);
    const [planning, setPlanning] = useState(false);
    const [test, setTest] = useState(false);
    const [testResults, setTestResults] = useState<ITestResults>({score: 0, plans: []});
    const handleActionChange: ChangeEventHandler<HTMLInputElement> = ({target}) =>
        setAction(target.value);
    const handleExecutePlanClick = (plan: Plan)=> () => {
        if (narrator && plan) {
            narrator.ExecutePlan(plan);
            setExecutedPlans(plans => [...plans, narrator?.Describe(plan)]);
            setLegalPlans([]);
        }
    }
    const handleFindPlanClick = () => {
        if (narrator) {
            setPlanning(true);
            setLegalPlans(narrator.FindAllPlans());
            setPlanning(false);
        }
    }
    const handleRandomChange: ChangeEventHandler<HTMLInputElement> = ({target}) => setRandom(target.checked);
    const handleTestAction = () => {
        setPlanning(true);
        setTestResults(narrator.TestAction(action));
        setPlanning(false);
    };
    const actionToPlans: Record<string, Plan[]> = {};

    legalPlans.forEach(plan => {
        actionToPlans[plan.GetAction().name] = [...actionToPlans[plan.GetAction().name] ?? [], plan];
    });
    useEffect(() => {
        if (logger) {
            setExecutedPlans(logger.GetLogs(["info"])
                .filter(log => log.text.match(/^Executed: /))
                .map(log => log.text.slice("Executed: ".length)));
        }
    }, [logger]);
    useEffect(() => {
        setActionNames(Object.fromEntries(Object.entries(actions || {})
            .map(([key, action]) => [key, action.name])
            .sort((lhs, rhs) => lhs[1].localeCompare(rhs[1]))));
    }, [actions]);
    return(
        <Box sx={styles.content}>
            <Accordion expanded={test} onChange={() => setTest(!test)}>
                <AccordionSummary expandIcon={<ExpandMore/>}>
                    <Typography>Test Tools</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={styles.tools}>
                        <Box flexGrow={1}>
                            <Typography>Action: {actionNames[action]}</Typography>
                            <Typography>Score: {testResults.score}</Typography>
                            <List>
                                {testResults.plans.map((plan, index) =>
                                    <ListItemText key={index} primary={plan}/> 
                                )}
                            </List>
                        </Box>
                        <Box>
                            <TextField label="Action" onChange={handleActionChange} select size="small"
                                       sx={{width: 200}} value={action} variant="standard">
                                <MenuItem value="" disabled>Select Action</MenuItem>
                                {Object.entries(actionNames).map(([key, name]) =>
                                    <MenuItem key={key} value={key}>{name}</MenuItem>
                                )}
                            </TextField>
                            <Button disabled={action === ""} onClick={handleTestAction} variant="contained"
                                    size="small" sx={{m:1}}>
                                Test Action
                            </Button>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Box sx={styles.tools}>
                <FormControlLabel control={
                    <Switch checked={random} onChange={handleRandomChange}/>
                } label={random ? "Random" : "Deterministic"}/>
                <Button disabled={planning} onClick={handleFindPlanClick} variant="contained"
                        size="small" sx={{m:1}}>
                    Find Plan
                </Button>
            </Box>
            <Box sx={styles.plans}>
                <Box sx={styles.legalPlans}>
                    {Object.keys(actionToPlans).map((action, index) =>
                        <Accordion key={index}>
                            <AccordionSummary>
                                <Typography>{action}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {actionToPlans[action].map((plan, index) =>
                                    <div key={index}>
                                        <Typography>{plan.toString(narrator.GetAliases())}</Typography>
                                        <Button onClick={handleExecutePlanClick(plan)} variant="contained" size="small"
                                                sx={{m: 1}}>
                                            Execute Plan
                                        </Button>
                                    </div>
                                )}
                            </AccordionDetails>
                        </Accordion>)
                    }
                </Box>
                <Box sx={styles.executedPlans}>
                    <AutoScroll content={executedPlans}/>
                </Box>
            </Box>
        </Box>
    );
}
const mapStateToProps = (store: never) => ({
    actions: selectActions(store),
});

const connected = connect(mapStateToProps, {})(PlayPlan);

export {connected as PlayPlan};