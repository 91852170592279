import {MutationAdd} from "./mutationAdd";
import {MutationDelete} from "./mutationDelete";
import {Mutation} from "./mutation";

export class Factory {
    private static mutationTypes: {[key: string]: any} = {
        add: MutationAdd,
        delete: MutationDelete
    };

    public static Create(data: any) {
        try {
            const mutation = new Factory.mutationTypes[data.type](data) as Mutation;

            mutation.Load(data);
            return mutation;
        } catch (exception) {
            console.error(`Failed to create mutation with this data: ${JSON.stringify(data)}`);
            throw exception;
        }
    }

}