import {ReactElement} from "react";
import {Box, Typography, useTheme} from "@mui/material";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

interface IBorderedBox {
    children: ReactElement | ReactElement []
    label?: string
    sx?: SxProps<Theme>;
}
export default function BorderedBox({children, label, sx}: IBorderedBox) {
    const theme = useTheme();
    const styles = {
        body: {
            height: "calc(100% - 28px)",
            marginTop: "-10px",
            paddingBottom: theme.spacing(1),
            paddingTop: theme.spacing(1)
        },
        bordered: {
            borderColor: "lightGrey",
            borderRadius: "5px",
            borderStyle: "solid",
            borderWidth: 1,
        },
        content: {
            height: "100%"
        },
        label: {
            position: "relative",
            color: "rgba(0, 0, 0, 0.6)",
            background: theme.palette.background.paper,
            left: "10px",
            paddingLeft: "4px",
            paddingRight: "4px",
            top: 0
        }
    };

    return (
        <Box sx={{...styles.content, ...sx}}>
            {label && label !== "none" && <Typography sx={styles.label} variant="caption">{label}</Typography>}
            <Box sx={label === "none" ? styles.body : {...styles.body, ...styles.bordered}}>
                {children}
            </Box>
        </Box>
    );
}