import {Box, TextField} from "@mui/material";
import {IActionData} from "../../../../aristotle/action";
import {ChangeEvent} from "react";

interface IActionDetails {
    action: IActionData
    editable: boolean
    onChange: (member: string, value: any) => void
}
export function ActionDetails({action, editable, onChange}: IActionDetails) {
    const handleChange = (member: string) => ({target}: ChangeEvent<HTMLInputElement>) => {
        onChange(member, target.value);
    }

    return (
        <Box display="flex" flexDirection="column" flexGrow={1} maxHeight="100%">
            <TextField disabled={!editable} label="Name" onChange={handleChange("name")} size="small"
                       sx={{m:1}} value={action.name}/>
            <TextField disabled={!editable} label="Score" onChange={handleChange("score")}
                       size="small" sx={{m:1}} type="number" value={action.score}/>
            <TextField disabled={!editable} label="Decay" onChange={handleChange("decay")} size="small"
                       sx={{m:1}} title="How much the score is reduced by with each successive reuse" type="number"
                       value={action.decay}/>
            <TextField disabled={!editable} label="Description" onChange={handleChange("description")}
                       multiline size="small" sx={{m:1, flexGrow: 1, overflowY: "auto"}} value={action.description}/>
        </Box>
    );
}