export type TValue = boolean | string | number | string[]
type TBound = {[member: string]: TValue}

export class Hypothesis {
    private readonly bound: TBound = {};
    
    constructor(hypothesis?: Hypothesis) {
        this.bound = hypothesis ? {...hypothesis.bound} : {};
    }

    public Bind(hypothesis: Hypothesis) {
        Object.keys(hypothesis.bound).forEach(key => this.bound[key] = hypothesis.bound[key]);
    }
    public get = (key: string) => this.bound[key];
    public set = (key: string, value: TValue) => this.bound[key] = value;
    public toString (aliases: Record<string, string> = {}) {
        return Object.keys(this.bound).map(key => {
            const bound = this.bound[key] as string;

            return `${key}: ${aliases[bound] ?? bound}`
        }).join(", ");
    }
}