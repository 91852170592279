import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Box, FormControl,
    FormControlLabel,
    FormGroup,
    IconButton, InputLabel, MenuItem, Select, SelectChangeEvent,
    Switch, TextField,
    Typography
} from "@mui/material";
import {IProjectRoles} from "../../redux/slices/user";
import React, {useContext, useEffect, useState} from "react";
import {FirebaseContext} from "../firebase/FirebaseProvider";
import {Add, Delete} from "@mui/icons-material";

interface IUserRoles {
    roles: IProjectRoles
    userId?: string
    inviteId?: string
}
export function RoleDetails({roles, userId, inviteId}: IUserRoles) {
    const firebase = useContext(FirebaseContext);
    const [addProject, setAddProject] = useState("");
    const [projects, setProjects] =
        useState<Record<string, any>>({});
    const handleAddProjectClick = () => {
        firebase.set(`users/${userId}/roles/projects/${addProject}`, {
            editor: false,
            viewer: true
        }).catch(console.error);
    }
    const handleDeleteClick = (project: string) => () => {
        firebase.remove(`users/${userId}/roles/projects/${project}`).catch(console.error);
    }
    const handleRoleToggle = (role: "admin" | "editor" | "viewer") => () => {
        if (userId) {
            firebase.set(`users/${userId}/roles`, {...roles, [role]: ! roles[role]}).catch(console.error);
        } else if (inviteId) {
            firebase.set(`invites/${inviteId}/roles`, {...roles, [role]: ! roles[role]}).catch(console.error);
        }
    };
    const handleProjectAddChange = (event: SelectChangeEvent) => {
        setAddProject(event.target.value);
    };
    const handleProjectRoleToggle = (project: string, role: "editor" | "viewer") => () => {
        if (userId) {
            firebase.set(`users/${userId}/roles/projects/${project}`, {...roles, [role]: ! roles[role]})
                .catch(console.error);
        }
    }

    useEffect(() => {
        firebase.get("projects").then(projects => {
            setProjects(projects as Record<string, any>)
        });
    }, [firebase]);
    return (
        <FormGroup>
            <FormControlLabel control={
                <Switch checked={roles.admin} onChange={handleRoleToggle("admin")}/>
            } label="Admin"/>
            <FormControlLabel control={
                <Switch checked={roles.editor} onChange={handleRoleToggle("editor")}/>
            } label="Editor"/>
            <FormControlLabel control={
                <Switch checked={roles.viewer} onChange={handleRoleToggle("viewer")}/>
            } label="Viewer"/>
            <Box display="flex">
                <FormControl size="small" sx={{flexGrow:1, marginBottom: 1}}>
                    <InputLabel id="project-add">Add project</InputLabel>
                    <Select label="Add project" onChange={handleProjectAddChange} value={addProject}>
                        <MenuItem value="">None</MenuItem>
                        {Object.keys(projects).map(key =>
                            <MenuItem key={key} value={key}>{projects[key].name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <IconButton disabled={addProject === "" || projects[addProject] === undefined}
                            onClick={handleAddProjectClick}>
                    <Add/>
                </IconButton>
            </Box>
            {Object.keys(roles.projects ?? {}).map((project, index) =>
                <Accordion key={index}>
                <AccordionSummary>
                        <Typography flexGrow={1}>{projects[project]?.name ?? project}</Typography>
                        <IconButton onClick={handleDeleteClick(project)} sx={{p:0}}><Delete/></IconButton>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormControlLabel control={
                            <Switch checked={roles.editor} onChange={handleProjectRoleToggle(project, "editor")}/>
                        } label="Editor"/>
                        <FormControlLabel control={
                            <Switch checked={roles.viewer} onChange={handleProjectRoleToggle(project, "viewer")}/>
                        } label="Viewer"/>
                    </AccordionDetails>
                </Accordion>
            )}
        </FormGroup>
    );
}