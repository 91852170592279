    import {Domain} from "../domain";
import {Hypothesis} from "../hypothesis";
import {Factory} from "./factory";
import {Predicate} from "./predicate";

export class PredicateNot extends Predicate {
    protected predicate?: Predicate;

    public describe(aliases: Record<string, string>): string {
        return `NOT (${this.predicate?.describe(aliases)})`
    }
    public Load(data: any) {
        this.predicate = data.predicate ? Factory.Create(data.predicate) : Factory.Create({type: "true"});
    }
    public Save(): any {
        return {
            predicate: this.predicate?.Save(),
            type: this.type
        };
    }
    UnifyDomain(domain: Domain, hypotheses: Hypothesis[]): boolean {
        const unified: Hypothesis[] = [];

        for (let hypothesis of hypotheses) {
            const working = [new Hypothesis(hypothesis)];

            if (! this.predicate?.UnifyDomain(domain, working)) {
                unified.push(new Hypothesis(hypothesis));
            }
        }
        hypotheses.splice(0, hypotheses.length, ...unified);
        return unified.length > 0;
    }
}
