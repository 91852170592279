import {Domain} from "../domain";
import {Hypothesis} from "../hypothesis";
import {PredicateCompound} from "./predicateCompound";

export class PredicateOr extends PredicateCompound {
    public describe(): string {
        return `(${Object.keys(this.predicates).map(key => this.predicates[key].describe).join(" OR ")})`;
    }
    UnifyDomain(domain: Domain, hypotheses: Hypothesis[]) {
        const unified: Hypothesis[] = [];

        for (let hypothesis of hypotheses) {
            const working = [new Hypothesis(hypothesis)];

            for (let predicate of Object.values(this.predicates)) {

                if (predicate.UnifyDomain(domain, working)) {
                    unified.splice(unified.length, 0, ...working);
                }
            }
        }
        hypotheses.splice(0, hypotheses.length, ...unified);
        return unified.length > 0;
    }
}
