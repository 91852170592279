import {Hypothesis} from "../hypothesis";
import {Parameter} from "./parameter";
import {ParameterFree} from "./parameterFree";

export class ParameterString extends Parameter {
    private value: string = "";

    public Equals(parameter: Parameter, hypothesis: Hypothesis): boolean {
        if (parameter instanceof ParameterString) {
            return this.value === parameter.value;
        } else if (parameter instanceof ParameterFree) {
            return this.value === hypothesis.get(parameter.value);
        }
        return false;
    }
    public Load(data: any) {
        if (data.value !== undefined) {
            this.value = data.value;
        }
    }
    public Save() {
        return {
            type: this.type,
            value: this.value
        };
    }
    public Unifies(parameter: Parameter, hypothesis: Hypothesis): boolean {
        if (this.Equals(parameter, hypothesis)) {
            return true;
        } else if (parameter instanceof ParameterFree && hypothesis.get(parameter.value) === undefined) {
            hypothesis.set(parameter.value, this.value);
            return true;
        }
        return false;
    }

    public toString = () => this.value as string;
}
