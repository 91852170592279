import {Hypothesis} from "../hypothesis";
import {Parameter} from "./parameter";
import {Factory} from "./factory";

export class ParameterFree extends Parameter {
    public value: string = "";

    public Bind(hypothesis: Hypothesis) {
        const value = hypothesis.get(this.value);

        if (typeof(value) === "string") {
            return Factory.Create({type: "string", value});
        } else if (typeof(value) === "boolean") {
            return Factory.Create({type: "boolean", value});
        } else if (typeof(value) === "number") {
            return Factory.Create({type: "number", value});
        }
        return Factory.Create({type: "array", value});
    }
    public Equals(parameter: Parameter, hypothesis: Hypothesis): boolean {
        const value = this.value as string;

        return (parameter instanceof ParameterFree &&
            hypothesis.get(value) === hypothesis.get(parameter.value as string) &&
            hypothesis.get(value) !== undefined) || parameter.Equals(this, hypothesis);
    }
    public Load(data: any) {
        if (data.value !== undefined) {
            this.value = data.value;
        }
    }
    public Save() {
        return {
            type: this.type,
            value: this.value
        };
    }
    public Unifies(parameter: Parameter, hypothesis: Hypothesis): boolean {
        if (this.Equals(parameter, hypothesis)) {
            return true;
        } else if (parameter instanceof ParameterFree && hypothesis.get(parameter.value) === undefined) {
            hypothesis.set(parameter.value, this.value);
            return true;
        }
        return false;
    }

    public toString = () => `{${this.value}}`;
}