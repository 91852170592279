import ModePortal from "../ModePortal";
import {Box, IconButton, Tab, Tabs} from "@mui/material";
import {SyntheticEvent, useState} from "react";
import Actions from "./actions/Actions";
import DomainPredicates from "./predicates/DomainPredicates";
import Entities from "./entities/Entities";
import Project from "./Project";
import {Help} from "@mui/icons-material";
import {connect} from "react-redux";
import {Update} from "../../../redux/slices/base";
import {IModeState, update as updateMode} from "../../../redux/slices/mode";
import {selectProject} from "../../../redux/selectors/firebase";
import {TFirebaseTable} from "../../../redux/slices/firebase";
import {selectUserDetails, selectUserId} from "../../../redux/selectors/user";
import {IUserDetails} from "../../../redux/slices/user";
import {selectMode} from "../../../redux/selectors/mode";

interface IEdit {
    mode: IModeState
    project: TFirebaseTable
    updateMode: Update
    userDetails: IUserDetails
    userId: string
}
function Edit({mode, project, updateMode, userDetails, userId}: IEdit) {
    const [editState, setEditState] = useState("project");
    const handleEditStateChange = (event: SyntheticEvent, value: string) => setEditState(value);
    const handleHelpClick = () => {
        updateMode({store: ["meta", "help"], update: {subject: `edit.${editState}`, open: true}})
    }
    const editable = Boolean(project && (project.owner === userId ||
        (userDetails.roles.projects && mode.meta.project && userDetails.roles.projects[mode.meta.project]?.editor)));

    return (
        <ModePortal>
            <Box display="flex">
                <Tabs value={editState} onChange={handleEditStateChange} sx={{flexGrow: 1}}>
                    <Tab label="Project" value="project"/>
                    <Tab label="Actions" value="actions"/>
                    <Tab label="Predicates" value="predicates"/>
                    <Tab label="Entities" value="entities"/>
                </Tabs>
                <IconButton onClick={handleHelpClick} sx={{marginRight:4}}>
                    <Help/>
                </IconButton>
            </Box>
            <Box>
                {editState === "project" && <Project editable={editable}/>}
                {editState === "actions" && <Actions editable={editable}/>}
                {editState === "predicates" && <DomainPredicates editable={editable}/>}
                {editState === "entities" && <Entities editable={editable}/>}
            </Box>
        </ModePortal>
    );
}
const mapStateToProps = (store: never) => ({
    mode: selectMode(store),
    project: selectProject(store),
    userDetails: selectUserDetails(store),
    userId: selectUserId(store)
});

const connected = connect(mapStateToProps, {
    updateMode
})(Edit);

export {connected as Edit}