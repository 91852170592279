import {Hypothesis} from "../hypothesis";
import {PredicateDomain} from "./predicateDomain";
import {Parameter} from "../parameters/parameter";
import {Factory as ParameterFactory} from "../parameters/factory";
import {Factory as PredicateFactory} from "./factory";

export class PredicateEntity extends PredicateDomain {
    protected entityType?: Parameter;
    protected entityKey?: Parameter
    protected properties?: Parameter

    public describe(aliases: {[key: string]: string} = {}): string {
        const key = this.entityKey?.toString();
        const type = this.entityType?.toString();

        if (key) {
            const name = aliases[key] || key;

            if (type) {
                return `${name} (${type}) ${this.properties?.toString() ?? ""}`;
            } else {
                return `${name} ${this.properties?.toString() ?? ""}`;
            }
        } else {
            return "Undefined Entity";
        }
    }

    public DomainGroup(): string {
        return this.entityType && this.entityType.type === "string" ? this.entityType.toString() : "";
    }

    public Load(data: any) {
        this.entityKey = ParameterFactory.Create(data.entityKey || {type: "string"});
        this.entityType = ParameterFactory.Create(data.entityType || {type: "string"});
        this.properties = ParameterFactory.Create(data.properties || {type: "array"});
    }
    public Save(): any {
        return {
            entityKey: this.entityKey?.Save(),
            entityType: this.entityType?.Save(),
            properties: this.properties?.Save(),
            type: this.type
        };
    }

    public Bind(hypothesis: Hypothesis): PredicateEntity {
        const bound = PredicateFactory.Create({type: this.type}) as PredicateEntity;

        bound.entityKey = this.entityKey!.Bind(hypothesis);
        bound.entityType = this.entityType!.Bind(hypothesis);
        bound.properties = this.properties!.Bind(hypothesis);
        return bound;
    }
    public Unifies(predicate: PredicateDomain, hypothesis: Hypothesis) {
        if (this.type === predicate.type) {
            const working = new Hypothesis(hypothesis);
            const test = predicate as PredicateEntity;

            if (this.entityKey!.Unifies(test.entityKey!, working) &&
                this.entityType!.Unifies(test.entityType!, working) &&
                this.properties!.Unifies(test.properties!, working)) {
                hypothesis.Bind(working);
                return true;
            }
        }
        return false;
    }
}