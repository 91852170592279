import {ChangeEvent, useState} from "react";
import {Box, Button, Paper, TextField, Typography, useTheme} from "@mui/material";
import {Email} from "@mui/icons-material";
import {createUserWithEmailAndPassword, getAuth, getRedirectResult, GoogleAuthProvider, signInWithEmailAndPassword,
    signInWithPopup} from "firebase/auth";

export default function Login() {
    const theme = useTheme();
    const styles = {
        card: {
            padding: theme.spacing(4)
        },
        modal: {
            padding: theme.spacing(4),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        page: {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            padding: theme.spacing(8),
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "lightgray"
        }
    };
    const [signInEmail, setSignInEmail] = useState(window.localStorage.getItem("emailForSignIn") || "");
    const [registerEmail, setRegisterEmail] = useState("");
    const [signInPassword, setSignInPassword] = useState("");
    const [registerPassword, setRegisterPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [weakPassword, setWeakPassword] = useState(false);
    const [differentPassword, setDifferentPassword] = useState(false);
    const handleGoogleAuthenticate = async () => {
        const auth = getAuth();

        return (await getRedirectResult(auth)) || (await signInWithPopup(auth, new GoogleAuthProvider()));
    };
    const handleEmailAuthenticate = async () => {
        await signInWithEmailAndPassword(getAuth(), signInEmail, signInPassword);
    };
    const handleRegister = async () => {
        await createUserWithEmailAndPassword(getAuth(), registerEmail, registerPassword).catch(error => {
            console.error(error);
        });
    };
    const handleSignInEmailChange = ({target}: ChangeEvent<HTMLInputElement>) => {
        setSignInEmail(target.value);
    }
    const handleRegisterEmailChange = ({target}: ChangeEvent<HTMLInputElement>) => {
        setRegisterEmail(target.value);
    }
    const handleSignInPasswordChange = ({target}: ChangeEvent<HTMLInputElement>) => {
        setSignInPassword(target.value);
    }
    const handleRegisterPasswordChange = ({target}: ChangeEvent<HTMLInputElement>) => {
        setRegisterPassword(target.value);
        setWeakPassword(target.value.length > 0 && target.value.length < 6);
        setDifferentPassword(target.value !== confirmPassword);
    }
    const handleConfirmPasswordChange = ({target}: ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(target.value);
        setDifferentPassword(target.value !== registerPassword);
    }

    return (
        <Box sx={styles.page}>
            <Paper sx={styles.modal}>
                <Typography variant="h4">Flavourworks Pathway Tools</Typography>
                <Typography variant="body1">Please authenticate to continue.</Typography>
                <Box m={1} display="flex" flexDirection="column">
                    <Button onClick={handleGoogleAuthenticate}>
                        <img alt="Google authentication" src={"./google.png"}/>
                    </Button>
                </Box>
                <Typography variant="h6">Email Password Sign In</Typography>
                <Box m={1} display="flex" flexDirection="column">
                    <form>
                        <Box m={1}>
                            <TextField variant="outlined" label="Email" value={signInEmail}
                                       autoComplete="email" onChange={handleSignInEmailChange}/>
                        </Box>
                        <Box m={1}>
                            <TextField variant="outlined" label="Password" type="password" value={signInPassword}
                                       autoComplete="current-password" onChange={handleSignInPasswordChange}/>
                        </Box>
                        <Box m={1}>
                            <Button variant="outlined" disabled={signInEmail === ""}
                                    onClick={handleEmailAuthenticate} startIcon={<Email/>}>Login</Button>
                        </Box>
                    </form>
                </Box>
                <Typography variant="h6">Email Password Register</Typography>
                <Box m={1} display="flex" flexDirection="column">
                    <form>
                        <Box m={1}>
                            <TextField variant="outlined" label="Email" value={registerEmail}
                                       autoComplete="email" onChange={handleRegisterEmailChange}/>
                        </Box>
                        <Box m={1}>
                            <TextField variant="outlined" label={weakPassword ? "Too short!" :"Password"}
                                       type="password" value={registerPassword}
                                       autoComplete="new-password" error={weakPassword}
                                       onChange={handleRegisterPasswordChange}/>
                        </Box>
                        <Box m={1}>
                            <TextField variant="outlined" label="Confirm Password" type="password"
                                       autoComplete="new-password" value={confirmPassword}
                                       error={differentPassword}
                                       onChange={handleConfirmPasswordChange}/>
                        </Box>
                        <Box m={1}>
                            <Button
                                variant="outlined"
                                disabled={weakPassword || registerEmail === "" || registerPassword === "" ||
                                registerPassword !== confirmPassword}
                                onClick={handleRegister} startIcon={<Email/>}>Register
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Paper>
        </Box>
    );
}
