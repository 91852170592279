import {Box, Typography} from "@mui/material";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import {TFirebaseTable} from "../../redux/slices/firebase";
import {useEffect, useState} from "react";
import {Narrator} from "../../aristotle/narrator";

interface IDemo {
    project: string
}
export function Demo({project}: IDemo) {
    const [actions, setActions] = useState<TFirebaseTable>();
    const [entities, setEntities] = useState<TFirebaseTable>();
    const [narrative, setNarrative] = useState<string[]>([]);
    const [narrator, setNarrator] = useState<Narrator>();
    const [predicates, setPredicates] = useState<TFirebaseTable>();
    const [state, setState] = useState("loading");

    useEffect(() => {
        const storage = getStorage();
        const fetchStorage = (name: string) =>
            getDownloadURL(ref(storage, `${project}/${name}.json`)).then(url =>
                fetch(url).then(response =>
                    response.json()
                )
            );

        fetchStorage("actions").then(actions =>
            fetchStorage("entities").then(entities =>
                fetchStorage("predicates").then(predicates => {
                    setActions(actions);
                    setEntities(entities);
                    setPredicates(predicates);
                })));
    }, [project]);
    useEffect(() => {
        if (narrator === undefined) {
            const narrator = new Narrator();

            setNarrator(narrator);
        }
    }, [narrator]);
    useEffect(() => {
        if (narrator && actions && entities && predicates) {
            narrator.Load(actions, entities, predicates);
            setState("running");
        }
    }, [actions, entities, narrator, predicates]);
    useEffect(() => {
        if (narrator && state === "running") {
            const plan = narrator.FindPlan({mode: "random"});

            if (plan) {
                const line = narrator.Describe(plan);
                const count = narrative
                    .reduce((total, test) => total + (test === line ? 1 : 0), 0);

                if (count < 3) {
                    setNarrative(narrative => [...narrative, line]);
                    narrator.ExecutePlan(plan);
                }
            }
        }
    }, [narrative, narrator, state]);
    return (
        <Box display="flex" flexDirection="column">
            {narrative.map((line, index) =>
                <Typography key={index}>{line}</Typography>
            )}
        </Box>
    );
}
