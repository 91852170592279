import ModePortal from "../ModePortal";
import {SyntheticEvent, useCallback, useEffect, useState} from "react";
import {Box, IconButton, Tab, Tabs} from "@mui/material";
import {Narrator} from "../../../aristotle/narrator";
import {ILogEntry, Logger, TSeverity} from "../../../aristotle/logger";
import {connect} from "react-redux";
import {TFirebaseTable} from "../../../redux/slices/firebase";
import {selectProject} from "../../../redux/selectors/firebase";
import {TabContext, TabPanel} from "@mui/lab";
import {PlayPlan} from "./PlayPlan";
import {PlayDomain} from "./PlayDomain";
import {PlayLog} from "./PlayLog";
import {Help} from "@mui/icons-material";
import {update as updateMode} from "../../../redux/slices/mode";
import {Update} from "../../../redux/slices/base";

interface IPlay {
    logger: Logger
    narrator: Narrator
    project: TFirebaseTable
    updateMode: Update
}
function Play({logger, narrator, project, updateMode}: IPlay) {
    const styles = {
        content: {
            height: "100%"
        },
        tabContent: {
            height: "calc(100% - 49px)"
        },
        tabList: {
            display: "flex",
            borderBottom: 1,
            borderColor: "divider",
        },
        tabPanel: {
            height: "calc(100% - 49px)"
        }
    };
    const [debugLog, setDebugLog] = useState(false);
    const [log, setLog] = useState<ILogEntry[]>([]);
    const [logging, setLogging] = useState(false);
    const [tab, setTab] = useState("plan");
    const handleHelpClick = () =>
        updateMode({store: ["meta", "help"], update: {subject: `play.${tab}`, open: true}});
    const handleTabChange = (event: SyntheticEvent, tab: string) => setTab(tab);
    const updateLog = useCallback(() => {
        if (logger && logging) {
            const severity: TSeverity[] = ["info", "warn", "error"];

            if (debugLog) {
                severity.push("debug")
            }
            setLog(logger.GetLogs(severity));
        }
    }, [debugLog, logger, logging]);

    useEffect(() => {
        if (logger) {
            logger.RegisterDelegate(updateLog)
            return () => logger.UnregisterDelegate(updateLog);
        }
    }, [updateLog, logger]);
    if (project === undefined || narrator === undefined) return null;
    return (
        <ModePortal>
            <Box sx={styles.content}>
                <TabContext value={tab}>
                    <Box sx={styles.tabList}>
                        <Tabs onChange={handleTabChange} aria-label="Select play mode" sx={{flexGrow: 1}} value={tab}>
                            <Tab label="Plan" value="plan" />
                            <Tab label="Domain" value="domain" />
                            <Tab label="Log" value="log" />
                        </Tabs>
                        <IconButton onClick={handleHelpClick} title="Help" sx={{marginRight: 2}}>
                            <Help/>
                        </IconButton>
                    </Box>
                    <Box sx={styles.tabContent}>
                        <TabPanel value="plan" sx={styles.tabPanel}>
                            <PlayPlan logger={logger} narrator={narrator}/>
                        </TabPanel>
                        <TabPanel value="domain" sx={styles.tabPanel}>
                            <PlayDomain logger={logger} narrator={narrator}/>
                        </TabPanel>
                        <TabPanel value="log" sx={styles.tabPanel}>
                            <PlayLog debugLog={debugLog} log={log} logging={logging} logger={logger}
                                     setDebugLog={setDebugLog} setLogging={setLogging}/>
                        </TabPanel>
                    </Box>
                </TabContext>
            </Box>
        </ModePortal>
    );
}
const mapStateToProps = (store: never) => ({
    project: selectProject(store)
});

const connected = connect(mapStateToProps, {
    updateMode
})(Play);

export {connected as Play};