import {Box, Card, CardContent, useTheme} from "@mui/material";
import {ReactElement} from "react";

interface IModalDialog {
    children: ReactElement | ReactElement[] | null;
}
export function ModalDialog({children}: IModalDialog) {
    const theme = useTheme();
    const styles = {
        card: {
            padding: theme.spacing(4)
        },
        modal: {
            padding: theme.spacing(4),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }
    };

    return (
        <Box sx={styles.modal}>
            <Card sx={styles.card}>
                <CardContent>
                    {children}
                </CardContent>
            </Card>
        </Box>
    );
}