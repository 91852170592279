import {Box, Tab} from "@mui/material";
import {connect} from "react-redux";
import {useContext, useEffect, useState} from "react";
import {FirebaseContext} from "../firebase/FirebaseProvider";
import {TFirebaseTable} from "../../redux/slices/firebase";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {UserDetails} from "./UserDetails";
import {InviteDetails} from "./InviteDetails";

function AdminTools() {
    const [invites, setInvites] = useState<TFirebaseTable>({});
    const [tab, setTab] = useState("users");
    const [users, setUsers] = useState<TFirebaseTable>({});
    const firebase = useContext(FirebaseContext);

    useEffect(() => {
        firebase.track("invites", (invites) => {
            setInvites(invites);
        });
        firebase.track("users", (users) => {
            setUsers(users);
        });
        return () => {
            firebase.untrack("invites");
            firebase.untrack("users");
        };
    }, [firebase]);
    return (
        <Box display="flex" flexDirection="column" sx={{margin: 4}}>
            <TabContext value={tab}>
                <TabList onChange={(event, tab) => setTab(tab)}>
                    <Tab label="Users" value="users"/>
                    <Tab label="Invites" value="invites"/>
                </TabList>
                <TabPanel value="users">
                    <UserDetails users={users}/>
                </TabPanel>
                <TabPanel value="invites">
                    <InviteDetails invites={invites}/>
                </TabPanel>
            </TabContext>
        </Box>
    )
}
const mapStateToProps = (store: never) => ({
});

const connected = connect(mapStateToProps, {
})(AdminTools);

export {connected as AdminTools}