import {Box, List, ListItemText, useTheme} from "@mui/material";
import {ReactElement} from "react";

interface IAutoScroll {
    content: (string | ReactElement)[]
}
export function AutoScroll({content}: IAutoScroll) {
    const theme = useTheme();
    const styles = {
        story: {
            borderColor: "grey",
            borderStyle: "solid",
            borderRadius: 4,
            borderWidth: 1,
            padding: 1,
            whiteSpace: "pre-wrap"
        },
        scrolling: {
            height: "100%",
            margin: theme.spacing(1),
            overflowY: "scroll",
            '&::-webkit-scrollbar': {
                height: '0.4em',
                width: '0.4em'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
                borderRadius: '0.4em'
            }
        }
    };

    return (
        <Box sx={styles.scrolling}>
            <List>
                {content.map((line, index) =>
                    line as string ?
                        <ListItemText key={index} primary={line} primaryTypographyProps={{fontSize: "x-large"}}
                                      sx={styles.story}/> : line
                )}
            </List>
        </Box>
    );
}
