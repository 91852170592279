import {Domain} from "../domain";
import {Hypothesis} from "../hypothesis";
import {PredicateCompound} from "./predicateCompound";

export class PredicateAnd extends PredicateCompound {
    public describe(): string {
        return `(${Object.keys(this.predicates).map(key => this.predicates[key].describe()).join(" AND ")})`;
    }
    UnifyDomain(domain: Domain, hypotheses: Hypothesis[]): boolean {
        return Object.values(this.predicates).every(predicate => predicate.UnifyDomain(domain, hypotheses));
    }
}
