import {connect} from "react-redux";
import {selectMode} from "../../redux/selectors/mode";
import {IModeState, update as updateMode} from "../../redux/slices/mode";
import {useContext, useEffect, useState} from "react";
import {selectUserDetails, selectUserId} from "../../redux/selectors/user";
import {IUserDetails} from "../../redux/slices/user";
import {FirebaseContext} from "../firebase/FirebaseProvider";

interface ILocationState {
    mode: IModeState
    updateMode: typeof updateMode
    userDetails: IUserDetails
    userId: string
}
function LocationState({mode, updateMode, userDetails, userId}: ILocationState) {
    const firebase = useContext(FirebaseContext);
    const [pageLoading, setPageLoading] = useState(true);

    useEffect(() => {
        const searchParameters = new URLSearchParams(window.location.search);
        const modeName = searchParameters.get("mode");

        if (modeName && pageLoading) {
            const searchMeta = searchParameters.get("meta");
            const meta = searchMeta ? JSON.parse(decodeURIComponent(searchMeta)) : {};
            const update = {name: modeName, meta};

            if (meta.project) {
                firebase.get(`projects/${meta.project}/owner`).then(value => {
                    const owner = value === userId;
                    
                    if (modeName === "edit" && (owner || (userDetails.roles.projects &&
                        userDetails.roles.projects[meta.project].editor))) {
                        updateMode({update});
                    } else if (modeName === "play" && (owner || (userDetails.roles.projects &&
                        userDetails.roles.projects[meta.project].viewer))) {
                        updateMode({update});
                    } else {
                        updateMode({update: {name: "projects", meta: {}}});
                    }
                });
            }
            setPageLoading(false);
        } else {
            const url = new URL(window.location.href);

            searchParameters.set("mode", mode.name);
            if (Object.keys(mode.meta).length) {
                searchParameters.set("meta", encodeURIComponent(JSON.stringify(mode.meta)));
            } else {
                searchParameters.delete("meta");
            }
            url.search = searchParameters.toString();
            window.history.pushState(null, "", url.href);
        }
    }, [firebase, mode, pageLoading, updateMode, userDetails.roles.projects, userId]);
    return null;
}
const mapStateToProps = (store: never) => ({
    mode: selectMode(store),
    userDetails: selectUserDetails(store),
    userId: selectUserId(store)
});

export default connect(mapStateToProps, {
    updateMode
})(LocationState);
