import {Box, Dialog, IconButton, useTheme} from "@mui/material";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {selectMeta} from "../../redux/selectors/mode";
import {IModeMeta, update as updateMode} from "../../redux/slices/mode";
import {Close} from "@mui/icons-material";
import {Update} from "../../redux/slices/base";
import {Markdown} from "./Markdown";

interface IHelp {
    modeData: IModeMeta
    updateMode: Update
}
function Help({modeData, updateMode}: IHelp) {
    const [source, setSource] = useState("");
    const [subject, setSubject] = useState("");
    const handleClose = () => {
        updateMode({store: ["meta", "help", "open"], update: false});
    }
    const theme = useTheme();
    const styles = {
        close: {
            position: "absolute",
            top: theme.spacing(1),
            right: theme.spacing(1)
        },
        markdown: {
            padding: 4
        }
    };

    useEffect(() => {
        if (modeData.help?.subject && modeData.help.subject !== subject) {
            setSubject(modeData.help.subject);
            setSource(`help/${modeData.help.subject}.md`);
        }
    }, [modeData, subject]);
    return (
        <Dialog open={Boolean(modeData.help?.open)} onClose={handleClose}>
            <Box sx={styles.markdown}>
                <IconButton onClick={handleClose} sx={styles.close}>
                    <Close/>
                </IconButton>
                <Markdown source={source}/>
            </Box>
        </Dialog>
    );
}

const mapStateToProps = (store: never) => ({
    modeData: selectMeta(store),
});

const connected = connect(mapStateToProps, {
    updateMode
})(Help);

export {connected as Help};

 