import MuiMarkdown from "mui-markdown";
import {List, ListItemText, Typography} from "@mui/material";
import {useEffect, useState} from "react";

interface IMarkdown {
    source: string
}
export function Markdown({source}: IMarkdown) {
    const [content, setContent] = useState("");
    const overrides = {
        h1: {
            component: Typography,
            props: {
                variant: "h1"
            }
        },
        li: {
            component: ListItemText,
            props: {
            }
        },
        ul: {
            component: List,
            props: {
                dense: true
            }
        }
    };

    useEffect(() => {
        // Prevent complaint about request as dependency of expression
        import(`../../data/${source}`).then(response => {
            return fetch(response.default)
        })
            .then(result => result.text()).then(setContent).catch(console.error);
    }, [source]);
    return (
        <MuiMarkdown children={content} overrides={overrides}/>
    );
}