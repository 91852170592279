import {ILogEntry, Logger} from "../../../aristotle/logger";
import {Box, FormControlLabel, IconButton, Switch, Typography} from "@mui/material";
import {Delete} from "@mui/icons-material";
import {AutoScroll} from "../AutoScroll";
import {useEffect, useState} from "react";

const maxLogLength = 80;

interface IPlayLog {
    debugLog: boolean
    log: ILogEntry[]
    logging: boolean
    logger: Logger
    setLogging: (debug: boolean) => void
    setDebugLog: (debug: boolean) => void
}
export function PlayLog({debugLog, log, logging, logger, setDebugLog, setLogging}: IPlayLog) {
    const styles = {
        content: {
            height: "100%",
        },
        log: {
            height: "calc(100% - 40px)"
        },
    }
    const logColor: {[severity: string]: string} = {
        debug: "primary",
        info: "info",
        warning: "warning",
        error: "error"
    }
    const [output, setOutput] = useState<ILogEntry[]>([]);
    const handleDeleteLog = () => {
        setOutput([]);
        logger.Clear();
    }

    useEffect(() => {
        if (log.length > 0) {
            setOutput([...log].slice(-maxLogLength));
        }
    }, [log]);
    return (
        <Box sx={styles.content}>
            <Box display="flex" m={1}>
                <Typography flexGrow={1} variant="h6">Log</Typography>
                <FormControlLabel control={
                    <Switch checked={logging} onChange={() => setLogging(! logging)}/>
                } label="Logging Enabled"/>
                <FormControlLabel control={
                    <Switch checked={debugLog} disabled={!logging} onChange={() => setDebugLog(! debugLog)}/>
                } label="Debug Log"/>
                <IconButton color="primary" onClick={handleDeleteLog}>
                    <Delete/>
                </IconButton>
            </Box>
            <Box sx={styles.log}>
                <AutoScroll content={output.map((logEntry, index) =>
                    <Box key={index} display="flex">
                        <Typography component="pre" variant="body2" sx={{width: 64}}>
                            {new Date(logEntry.timestamp).toLocaleTimeString()}
                        </Typography>
                        <Typography color={logColor[logEntry.severity]} component="pre" flexGrow={1} variant="body2">
                            {logEntry.text}
                        </Typography>
                    </Box>)}/>
            </Box>
        </Box>
    );
}