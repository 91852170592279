interface IToken {
    type: string,
    value: string
}

export class Parser
{
    static tryMatch(pattern: RegExp, test: string, callback: (result: RegExpMatchArray) => void): boolean {
        const match = test.match(pattern);

        if (match) {
            callback(match);
            return true;
        }
        return false;
    };

    static tokenise(expression: string, patterns: {[key: string]: RegExp}): IToken[] {
        const testTokens = (token: string) => this.tryMatch(patterns[token], working, (match) => {
            const value = match[1].replaceAll("~", "\n");

            results.push({type: token, value})
            working = working.substring(match[0].length);
        });
        const results: IToken[] = [];
        let working = expression.replaceAll("\n", "~");

        while(working.length) {
            if (! Object.keys(patterns).some(testTokens)) {
                throw new Error(`Failed to parse ${expression} stopped at ${working}`);
            }
        }
        return results;
    }
}