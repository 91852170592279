import {PredicateDomain} from "../predicates/predicateDomain";
import {Factory} from "../predicates/factory";
import {Hypothesis} from "../hypothesis";

export abstract class Mutation {
    public readonly type: string;
    protected predicate?: PredicateDomain;

    protected constructor(data: any) {
        this.type = data.type;
    }

    public Bind(hypothesis: Hypothesis) {
        if (this.predicate) {
            return this.predicate.Bind(hypothesis);
        }
    }
    public Load(data: any) {
        this.predicate = Factory.Create(data.predicate || {type: "true"});
    }
    public Save(): any {
        return {
            type: this.type,
            predicate: this.predicate?.Save()
        };
    }
    public abstract describe(aliases?: {[key: string]: string}): string;
}
