import {selectEntities, selectProject} from "../../../../redux/selectors/firebase";
import {connect} from "react-redux";
import {Box} from "@mui/material";
import Parameter from "./Parameter";
import {TFirebaseTable} from "../../../../redux/slices/firebase";

function SetPredicateParameters(data: any, project: TFirebaseTable, free?: string[]) {
    if (project.relationshipTypes) {
        data.relationship.type = "string";
        data.relationship.value = Object.keys(project.relationshipTypes)[0];
    }
    if (free?.length) {
        data.subject.type = "free";
        data.subject.value = free[0];
        data.object.type = "free";
        data.object.value = free[0];
    }
}

interface IPredicateRelatesDetails {
    data: any
    editable: boolean
    entities: TFirebaseTable
    free?: string[]
    onChange: (type: any) => void
    project: TFirebaseTable
}
function PredicateRelatesDetails({data, editable, entities, free, onChange, project}: IPredicateRelatesDetails) {
    const handleMemberChange = (member: string) => (value: any) => {
        onChange({
            ...data,
            [member]: value
        });
    }

    return (
        <Box m={1}>
            <Parameter data={data.relationship} editable={editable} label="Relationship"
                       onChange={handleMemberChange("relationship")}
                       types={["free", "string", "select"]} select={project.relationshipTypes}/>
            <Parameter data={data.object} editable={editable} free={free} label="Object"
                       onChange={handleMemberChange("object")}
                       types={["free", "string", "select"]} select={
                Object.fromEntries(Object.entries(entities)
                    .map(([key, entity]) => [key, entity.name]))}/>
            <Parameter data={data.subject} editable={editable} free={free} label="Subject"
                       onChange={handleMemberChange("subject")}
                       types={["free", "string", "select"]} select={
                Object.fromEntries(Object.entries(entities)
                    .map(([key, entity]) => [key, entity.name]))}/>
        </Box>
    );
}
const mapStateToProps = (store: never) => ({
    entities: selectEntities(store),
    project: selectProject(store)
});

const connected = connect(mapStateToProps, {
})(PredicateRelatesDetails);

export {connected as PredicateRelatesDetails, SetPredicateParameters}