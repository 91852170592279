import {Box, Divider, MenuItem, TextField, useTheme} from "@mui/material";
import {Factory} from "../../../../aristotle/predicates/factory";
import BorderedBox from "../BorderedBox";
import {ChangeEventHandler, createElement} from "react";
import Predicates from "./Predicates";
import {PredicateEntityDetails, SetPredicateParameters as SetEntityPredicateParameters} from "./PredicateEntityDetails";
import {PredicateRelatesDetails, SetPredicateParameters as SetRelationshipPredicateParameters} from "./PredicateRelatesDetails";
import {selectEntities, selectProject} from "../../../../redux/selectors/firebase";
import {connect} from "react-redux";
import {TFirebaseTable} from "../../../../redux/slices/firebase";

const predicateDetails: {[type:string]: any} = {
    "entity": PredicateEntityDetails,
    "relates": PredicateRelatesDetails
};

interface IPredicate {
    data: any
    editable: boolean
    entities: TFirebaseTable
    free?: string[]
    label?: string
    onChange: (type: any) => void
    project: TFirebaseTable
}
function Predicate({data, editable, entities, free, label, onChange, project}: IPredicate) {
    const theme = useTheme();
    const styles = {
        content: {
            display: "flex",
            flexDirection: "column",
            height: "100%"
        },
        predicates: {
            height: "calc(100% - 58px)"
        },
        type: {
            flexGrow: 1,
            margin: theme.spacing(1)
        }
    };
    const aliases = Object.fromEntries(Object.entries(entities).map(([key, entity]) => [key, entity.name]));
    const predicate = Factory.Create(data);
    const description = label || predicate.describe(aliases);
    const handleMemberChange = (member: string) => (value: any) => {
        onChange({
            ...data,
            [member]: value
        });
    }
    const handleTypeChange:ChangeEventHandler<HTMLInputElement> = ({target}) => {
        const predicate = Factory.Create({type: target.value}).Save();

        if (predicate.type === "entity") {
            SetEntityPredicateParameters(predicate, project, free);
        } else if (predicate.type === "relates") {
            SetRelationshipPredicateParameters(predicate, project, free);
        }
        onChange(predicate);
    };

    return (
        <BorderedBox label={description}>
            <Box sx={styles.content}>
                <Box display="flex">
                    <TextField disabled={!editable} label="Type" onChange={handleTypeChange} select size="small"
                               sx={styles.type} value={data.type}>
                        <MenuItem value="" disabled><em>None</em></MenuItem>
                        <MenuItem value="and">And</MenuItem>
                        <MenuItem value="or">Or</MenuItem>
                        <MenuItem value="not">Not</MenuItem>
                        <Divider/>
                        <MenuItem value="entity">Entity</MenuItem>
                        <MenuItem value="relates">Relationship</MenuItem>
                        <MenuItem value="true">True</MenuItem>
                    </TextField>
                </Box>
                <Box sx={styles.predicates}>
                    {predicateDetails[predicate.type] &&
                        createElement(predicateDetails[predicate.type], {data, editable, free, onChange})}
                    {data.predicate &&
                        <ConnectedPredicate data={data.predicate} editable={editable} free={free}
                                            onChange={handleMemberChange("predicate")}/>
                    }
                    {data.predicates &&
                        <Predicates data={data.predicates} editable={editable} free={free}
                                    onChange={handleMemberChange("predicates")}/>
                    }
                </Box>
            </Box>
        </BorderedBox>
    );
}
const mapStateToProps = (store: never) => ({
    entities: selectEntities(store),
    project: selectProject(store)
});
const ConnectedPredicate = connect(mapStateToProps, {
})(Predicate);

export default ConnectedPredicate;
