import {TFirebaseTable} from "../../redux/slices/firebase";
import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import {useState} from "react";
import {RoleDetails} from "./RoleDetails";

interface IUserDetails {
    users: TFirebaseTable
}
export function UserDetails({users}: IUserDetails) {
    const [expandedUser, setExpandedUser] = useState("");
    const handleUserChange = (user: string) => () => {
        setExpandedUser(user === expandedUser ? "" : user);
    };
    const styles = {
        contents: {
            height: "60vh",
            width: 400,
            marginBottom: 4
        },
        email: {
            color: "text.secondary",
        },
        name: {
        },
        summary: {
            display: "flex",
            justifyContent: "space-between",
            width: "100%"
        }
    };

    return (
        <Box sx={styles.contents}>
            {Object.entries(users).map(([key, user]) =>
                <Accordion expanded={expandedUser === key} onChange={handleUserChange(key)} key={key}>
                    <AccordionSummary expandIcon={<ExpandMore/>}>
                        <Box sx={styles.summary}>
                            <Typography sx={styles.name}>{user.name}</Typography>
                            <Typography sx={styles.email}>{user.email}</Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={styles.summary}>
                            <Typography sx={styles.name}>Created</Typography>
                            <Typography sx={styles.email}>{new Date(user.created).toLocaleString()}</Typography>
                        </Box>
                        <RoleDetails roles={user.roles} userId={key}/>
                    </AccordionDetails>
                </Accordion>
            )}
        </Box>
    );
}